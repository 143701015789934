.notFound{
    padding: 50px;
    padding-top: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 1180px;
    margin: 0 auto;
}

.notFound img{
    height: 100%;
    width: 100%;
    max-width: 600px;
}