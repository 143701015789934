.header {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
  padding: 30px 0;
  position: fixed;
  margin: 0 auto;
  width: 100%;
  z-index: 12;
  left: 50%;
  top: 0px;
  transform: translate(-50%, 0%);
}

.header.toggleNav {
  background: #fff;
  box-shadow: 0px 0px 15px -8px rgba(0, 0, 0, 0.5);
}

.close-icon {
  display: none;
}

.header__container {
  margin: 0 auto;
  max-width: 1180px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mobile__header {
  display: none;
}

.bg-color {
  background: url(images/dots.png);
  position: relative;
}

.header-lines {
  position: absolute;
  top: 0;
  z-index: -1;
}

.header__logo {
  width: 250px;
  cursor: pointer;
}

.header ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 65%;
}


.nav-drop {
  position: relative;
  text-align: center;
}


.drop-dropdown-container {
  display: flex;
  position: absolute;
  padding-top: 30px;
  width: fit-content;
  height: fit-content;
  z-index: 13;
  left: -100px;
}

.nav-drop ul {
  /* position: absolute; */
  padding: 30px;
  background: linear-gradient(110.23deg, rgba(255, 255, 255, 0.63) 26.99%, rgba(255, 219, 219, 0) 157.62%);
  backdrop-filter: blur(15px);
  background: linear-gradient(110.23deg, rgba(255, 255, 255, 0.63) 26.99%, rgba(255, 219, 219, 0) 157.62%);
  display: flex;
  gap: 40px;
  border-radius: 5px;
  font-size: 1rem;
  transition: opacity 0.5s 0.1s, top 0s 1s;
  /* position: absolute; */
  width: 800px;
  flex-wrap: wrap;
  /* left: -100%; */
  /* margin-top: 50px; */
  box-shadow: 4px 8px 31px 0px #00000014;
  border: 5px solid #FFFFFF;
  border-radius: 50px;
  display: none;
}

.nav-drop ul.navActive {
  display: flex;
}

.drop-text {
  font-weight: 700;
  font-size: 1rem;
  text-decoration: none;
  position: relative;
  cursor: pointer;
  justify-content: space-evenly;
  display: flex;
  align-items: center;
}

.drop-dropdown {
  width: 100%;
  min-width: 250px;
  /* z-index: 5; */
}

.dropdown__wrapper{
  width: 800px;
    height: 400px;
    /* background: #000; */
    position: absolute;
    left: -100%;
}

.dropdown__wrapper{
  min-width: 690px;
  left: -145%;
}

.nav-drop .navIcon{
  background: transparent;
  margin-left: 10px;
  width: 17px;
  height: 17px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #BB2525;
  transition: .5s all ease-in-out;
  transform: rotate(0deg);
}

.nav-drop.navActive .navIcon {
  transform: rotate(180deg);
}

.nav-drop ul a {
  width: max-content;
  color: #fff;
  font-size: 1rem;
}

.main-nav-cont ul a:hover::after {
  background-color: #fff;
}

.nav-drop ul a:hover {
  color: #fff;
}

.nav-drop ul a {
  white-space: pre;
  font-weight: 600;
  width: 47%;
  justify-content: space-between;
  display: flex;
  color: #000;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  z-index: 5;
}

.nav-drop ul a:hover {
  color: #BB2525;
}

/* .nav-drop ul::before{
  content: '';
  backdrop-filter: blur(5px);
  background: linear-gradient(110.23deg, rgba(255, 255, 255, 0.63) 26.99%, rgba(255, 219, 219, 0) 157.62%);
  width: 100%;
  height: 100%;
  position: absolute;
  border-radius: 40px;
  top: 0;
  left: 0;
} */

.header ul li {
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header .navlink {
  color: #000;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.header .navlink:hover {
  color: #BB2525;
}

.header ul img {
  width: 30px;
  height: 30px;
}

.contact-btn, .mobile__header .contact-btn {
  border-radius: 10px;
  border: 1px solid #BB2525;
  background: linear-gradient(279deg, #EA8484 -97.6%, rgba(253, 217, 217, 0.15) 116.8%);
  box-shadow: 5px 1px 8px 0px rgba(0, 0, 0, 0.11);
  color: #BB2525;
  font-size: 16px;
  padding: 10px 20px;
  font-weight: 600;
  white-space: pre;
  transition: all .4s;
  position: relative;
  overflow: hidden;
  /* animation: pulse-effect 1s infinite; */
}

.contact-btn:before , .mobile__header .contact-btn:before{
  content: "";
  background-color: #fff;
  height: 155px;
  left: -75px;
  opacity: .1;
  position: absolute;
  top: -50px;
  width: 50px;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  transform: rotate(35deg);
  z-index: 1;
}

.contact-btn:hover,.mobile__header .contact-btn:hover{
  color: #fff;
  background: #BB2525;
  box-shadow: 0px 10px 30px #f97d9fc2;
}

.contact-btn:hover::before, .mobile__header .contact-btn:hover::before {
  left: 120%;
  transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
}

.header .navlink.active{
  color: #BB2525;
  font-weight: 700;
}

footer {
  background: #F6FCFF;
  background-image: url(./images/dots.png);
}

.footer-container {
  max-width: 1180px;
  margin: 0 auto;
  padding-top: 50px;
}

footer .footer__top {
  display: flex;
  justify-content: space-between;
}

footer .footer__top_left {
  width: 40%;
  display: flex;
  gap: 17px;
  flex-direction: column;
}

footer .footer__top_left p{
  font-size: 16px;
}

footer .footer__top_left img {
  width: 250px;
  height: 40px;
}

footer .footer__top_right {
  width: 60%;
}

footer .footer__services_container {
  display: flex;
  justify-content: space-between;
  column-gap: 20px;
  padding-left: 20px;
}

footer .footer__services {
  display: flex;
  flex-direction: column;
  width: 45%;
}

.footer__services h3 {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.footer__services ul {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  margin: 20px 0;
}

.footer__services ul li{
  list-style:disc;
}

.footer__services ul li a {
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 35px;
}

.footer__middle {
  border-top: 1px solid #989898;
  padding: 24px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
  padding-top: 50px;
}

.footer__services_container a:hover,
.footer__middle_cont a:hover {
  color: #BB2525;
  transition: .2s all ease-in-out;
}

.footer__middle_container {
  display: flex;
  flex-direction: column;
  width: 55%;
}

.footer__middle_container h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 35px
}

.footer__middle_cont {
  display: flex;
  justify-content: space-between;
  columns: 20px;
  gap: 20px;
}

footer .footer__middle_container ul {
  display: flex;
  flex-wrap: wrap;
  margin: 20px 0;
  max-width: 400px;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 0;
}

.footer__middle_container ul li{
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.footer__middle_container ul li:hover svg{
  fill: #BB2525;
}

.footer__middle_container ul li svg{
  min-width: 20px;
  min-height: 20px;
}

.footer__middle_container li a {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  color: #000;
  display: flex;
  gap: 10px;
}

.footer__middle_container .footer__middle_img{
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: linear-gradient(37.61deg, #1C2542 -43.74%, #BB2525 122.16%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer__social {
  width: 25%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border-radius: 20px;
  border: 1px solid #FFF;
  background: rgba(219, 242, 253, 0.60);
  align-items: center;
  justify-content: center;
  padding: 20px 40px;
  height: fit-content;
}

.footer__social h3 {
  font-size: 20px;
  font-weight: 700;
  line-height: 35px;
}

.footer__social ul {
  display: flex;
  width: 100%;
  columns: 10px;
  justify-content: space-evenly;
}

.footer__social li {
  border-radius: 50%;
  display: flex;
  width: 100%;
  max-width: 36px;
  height: 36px;
  background-color: #fff;
  justify-content: center;
  transition: .3s all ease-in-out;
  cursor: pointer;
}

.footer__social li:hover {
  background-color:#EA8484;
}

.footer__social li a{
  display: flex;
  justify-content: center;
  align-items: center;
}


.footer__copyright {
  background: #BB2525;
  color: #fff;
  text-align: center;
  padding: 5px;
}

.menu {
  display: none;
}

.text-align-center {
  text-align: center;
}


.mob-close-icon{
  display: none;
}

.overlay.active{
  background: linear-gradient(110.23deg, rgba(255, 255, 255, 0.63) 26.99%, rgba(255, 219, 219, 0) 157.62%);
  backdrop-filter: blur(5px);
  background: linear-gradient(110.23deg, rgba(255, 255, 255, 0.63) 26.99%, rgba(255, 219, 219, 0) 157.62%);
  transition: opacity 0.5s 0.1s, top 0s 1s;
  position: fixed;
  height: 100%;
  width: 100%;
  z-index: 5;
}

.nav-projects .drop-dropdown-container ul{
  max-width: fit-content;
}

@media screen and (min-width:767px) {
  .nav-drop:hover .navIcon{
    background: linear-gradient(37.61deg, #1C2542 -43.74%, #BB2525 122.16%);
    color: #fff;
  }

  .nav-drop:hover .navIcon {
    transform: rotate(180deg);
  }

  .nav-drop:hover ul {
    display: flex;
  }
}

@media screen and (max-width:1180px) {


  .header-lines {
    width: 30%;
  }

  .header {
    padding: 30px 20px;
  }

  .header__logo {
    width: 150px;

  }

  .header ul {
    width: 70%;
    gap: 20px;
  }

  .header .navlink {
    font-size: 14px;
  }

  .contact-btn, .mobile__header .contact-btn {
    font-size: 14px;
  }

  .nav-drop .navIcon {
    margin-left: 5px;
  }

  footer,
  .hori__banner,
  .hero__banner {
    padding: 0 20px;
  }

  footer {
    padding-top: 50px;
  }

  footer .footer__services {
    width: 50%;
  }

  .footer__social {
    width: 40%;
    max-width: 300px;
  }

  .menu {
    display: flex;
    border-radius: 50%;
    padding: 20px;
    position: fixed;
    background-color: #fff;
    top: 70px;
    right: 20px;
    -webkit-box-shadow: 0px 0px 47px -9px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 47px -9px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 47px -9px rgba(0, 0, 0, 0.75);
    z-index: 10;
    display: none;
  }


  .footer__middle,
  .footer__top {
    flex-wrap: wrap;
    gap: 20px;
  }

  .footer-container {
    padding-top: 0;
    max-width: 950px;
  }

  .footer__social,
  .footer__middle_container,
  footer .footer__top_left,
  footer .footer__services {
    width: 100%;
  }

  .footer__middle_container,
  .footer__social{
    width: 48%;
  }

  .footer__middle_cont {
    gap: 0px;
    flex-direction: column;
  }

  footer .footer__services_container {
    padding-left: 20px;
  }

  .footer__services ul {
    flex-direction: column;
  }

  .nav-drop ul.navActive {
    display: flex;
    min-width: 690px;
}

footer .footer__middle_container ul:nth-child(2) {
  margin-top: 10px;
}

.drop-dropdown-container {
  left: -150px;
}

.drop-dropdown {
  min-width: 700px;
}

}

@media only screen and (max-width: 1200px) and (min-width: 768px) {

  footer .footer__services {
    max-width: 600px;
  }

}

@media screen and (max-width:767px) {

  .header .header__logo {
    display: none;
  }

  .header {
    display: none;
  }

  .mobile__header {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 20px;
    position: fixed;
    width: 100%;
    z-index: 12;
    justify-content: space-between;
  }

  .mobile__header.toggleNav {
    background: #fff;
  }

  .menu {
    display: block;
  }

  .menu .open,
  .menu.active .close {
    display: block;
  }

  .menu .close {
    display: none;
  }


  .header {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    justify-content: center;
    align-items: flex-start;
    display: flex;
    background-color: #fff;
    transform: translateX(100%);
    transition: .4s all ease-in-out;
    z-index: 13;
  }

  .footer__middle_container,
  .footer__social{
    width: 100%;
  }

  .header.active {
    transform: translateX(15%);
    box-shadow: 10px 10px 10px 10px rgba(0,0,0,.1);
  }

  .header ul {
    flex-direction: column;
    width: 100%;
    gap: 40px;
    align-items: flex-start;
    padding-left: 30px;
  }

  .nav-drop:hover ul {
    width: 100%;
    height: 100%;
    justify-content: center;
}

  .mobile__header .header__logo {
    width: 145px;
    height: 24px;
  }

  .drop-dropdown-container {
    display: flex;
    position: absolute;
    padding-top: 50px;
    transform: translateX(100%);
    z-index: 13;
    left: 0px;
    height: 100%;
    top: 0;
    justify-content: center;
    align-items: center;
    padding: 0;
    width: 100%;
    transition: .5s all ease-in-out;
}

.drop-dropdown-container.navActive {
  transform: translateX(0%);
}

  .menu {
    position: relative;
    right: 0;
    top: 0;
    box-shadow: unset;
    padding: 5px;
  }

  .contact-btn {
    font-size: 16px;
    padding: 10px;
  }

  .left__header {
    display: flex;
    gap: 10px;
    align-items: center;
  }

  .header__container {
    justify-content: center;
    margin-top: 50px;
  }

  .close-icon {
    position: absolute;
    top: 5%;
    right: 20%;
    display: block;
    font-size: 2rem;
  }

  .nav-drop ul {
    top: 0;
    width: 100%;
    max-height: unset;
    opacity: 1;
  }

  .nav-drop:hover svg {
    transform: unset;
  }

  .nav-drop.active svg {
    transform: rotate(180deg);
  }

  .nav-drop.active .drop-dropdown {
    display: flex;
    border: unset;
    border-radius: 0;
    box-shadow: unset;
    justify-content: flex-start;
    padding-top: 90px;
  }

  .footer__middle_container ul ,
  .footer__middle_cont,
  .footer__services ul ,
  footer .footer__services_container {
    flex-direction: column;
    margin: 0;
  }

  footer .footer__services_container {
    padding-left: 20px;
  }

  .footer__middle_cont {
    gap: 0;
  }

  .footer__middle_container h3 ,
  .footer__services h3 {
    margin-bottom: 10px;
  }

  .nav-drop ul.navActive {
    position: absolute;
    min-width: 360px;
    left: 0;
    height: 100%;
    margin: 0;
    justify-content: center;
  }

  .nav-drop ul a {
    width: 100%;
    font-size: 14px;
    gap: 10px;
  }

  .nav-drop {
    position: unset;
  }

  .mob-close-icon{
    z-index: 2;
    right: 20%;
    top: 30px;
    position: absolute;
    display: block;
    font-size: 2rem;
  }

  .dropdown__wrapper{
    width: unset;
    position: absolute;
    min-width: 100%;
    left: 0;
    height: 100%;
    margin: 0;
    justify-content: center;
    display: flex;
    top: 0;
    z-index: -1;
}

.footer__top {
  gap: 30px;
}

.dropdown__wrapper.navActive{
  z-index: 0;
}
.header ul img {
  width: 20px;
  height: 20px;
}

}