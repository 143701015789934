
/* top banner  */

.contactus-page{
    overflow: hidden;
}

.contactus-page .hero__banner .banner__circle1{
    border-radius: 50%;
    position: absolute;
    width: 1200px;
    height: 1200px;
    top: -510px;
    left: -100px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: .6;
}

.contactus-page .all-stats {
    display: none;
}

.contactus-page .hero__banner_right img {
    width: unset;

}

.contactus-page .contactUs__right{
    width: 100%;
    position: relative;
}

.contactus-page .hero__banner_right::before{
background: url(../../images/contactUs/contactUsFromBg.svg) no-repeat center;
position: absolute;
content: '';
width: 120%;
height: 120%;
}

.contactus-page .hero__banner .banner__circle2{
    border-radius: 50%;
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: 60px;
    top: -440px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: .8;
}

.contactus-page .hero__banner .banner__circle3{
    border-radius: 50%;
    position: absolute;
    width: 800px;
    height: 800px;
    left: 200px;
    top: -360px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: 1;
}

.contactus-page .hero__banner .banner__circle1::after,
.contactus-page .hero__banner .banner__circle2::after,
.contactus-page .hero__banner .banner__circle3::after{
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    left: 0px;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
}

.contactus-page .hero__banner .banner__circle1::after{
    bottom: 270px;
    left: 110px;
}
.contactus-page .hero__banner .banner__circle3::after{
    left: 230px;
    bottom: 0;
}

.contactus-page .hero__banner h3.main,
.contactus-page .hero__banner h3.main span{
    font-size: 45px;
    line-height: 65px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.contactus-page .hero__banner h3 span.redBold,
.contactus-page .hero__banner h3.main{
    font-size: 50px;
}

.contactus-page .hero__banner_left {
    max-width: 540px;
    z-index: 1;
    width: 51%;
    gap: 10px;
}

.contactus-page .hero__banner_wrapper::before {
    content: unset;
}

/* .contactus-page .hero__banner p {
    display: none;
} */

.contactus-page  .contactUs__right form h4 {
 margin-bottom: 0;
}

.contactus-page .hero__banner p {
    padding: 0;
}

.contactus-page .hero__banner_wrapper {
    margin-top: 0;
    padding-top: 200px;
    padding-bottom: 150px;
}

.contactus-page .title__small,
.contactus-page .redTitle{
    white-space: unset;
}

.contactus-page h3.main::before {
    width: 70px;
    height: 3px;
    top: -15px;
}

.contactus-page h3.main::after {
    top: -0px;
    width: 100px;
}

.contactus-page .hero__banner strong{
    padding: 0;
}

.contactus-page .contactUs__right::after,
.contactus-page .contactUs__right::before {
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    bottom: -50px;
    right: 40%;
    z-index: 1;
}

.contactus-page .contactUs__right::before{
    left: -10%;
    bottom: -5%;
    z-index: 3;
}


.contactus-page .contactUs__right::after{
    right: 0%;
    bottom: -15%;
    width: 60px;
    height: 60px;   
}

.contactus-page .data__container_right{
    margin: auto;
}

.contactus-page .contactUs__cards,
.contactus-page .contactUs__left{
    padding-bottom: 70px;
}

.contactus-page .contactUs::after{
    content: '';
    background: url(../../images/top-header-bg.svg);
    position: absolute;
    top: -2px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
    /* background-color: #fff; */
    background-size: contain;
    transform: rotate(180deg);
}


@media screen and (max-width: 1180px) {

    .contactus-page .hero__banner h3.main, .contactus-page .hero__banner h3.main span ,
    .contactus-page .hero__banner h3 span.redBold, .contactus-page .hero__banner h3.main {
        font-size: 36px;
        line-height: 45px;
    }

    .contactus-page .hero__banner_wrapper {
        padding: 50px 0;
        padding-top: 150px;
        padding-bottom: 80px;
    }

    .contactus-page .hero__banner_right{
        width: 60%;
    }
    .contactus-page .hero__banner_left {
        width: 40%;
    }
    .contactus-page .contactUs::after {
        top: -30px;
    }

}

@media  only screen and (max-width: 900px) and (min-width: 767px)  {

    .contactus-page .hero__banner {
        margin: 0;
        padding-bottom: 0;
        flex-direction: column;
    }

    .contactus-page .hero__banner_left,.contactus-page .hero__banner_right {
        width: 100%;
        max-width: unset;
    }

}

@media screen and (max-width: 767px) {

    .contactus-page .hero__banner_right::before,
    .contactus-page .contactUs__right::after,
.contactus-page .contactUs__right::before {
        display: none;
}

    .contactus-page .hero__banner_left {
        width: 100%;
    }

    .contactus-page .hero__banner {
        margin: 0;
        padding-bottom: 0;
        flex-direction: column;
        padding-top: 0;
        gap: 30px;
    }

    .contactus-page .redTitle {
        white-space: unset;
    }

    .contactus-page .hero__banner h3.main, .contactus-page .hero__banner h3.main span ,
    .contactus-page .hero__banner h3 span.redBold, .contactus-page .hero__banner h3.main {
        font-size: 28px;
        line-height: 35px;
    }

    .contactus-page .hero__banner .banner__circle1,
    .contactus-page .hero__banner .banner__circle2,
    .contactus-page .hero__banner .banner__circle3{
        display: none;
    }

    .contactus-page .hero__banner_right {
        width: 100%;
    }

    .contactus-page .hero__banner_wrapper {
        padding-top: 100px;
    }

    .contactus-page .data__container_right {
        display: flex;
    }

    .contactus-page .contactUs__wrapper .contactUs{
        flex-direction: column;
        gap: 0;
        padding-bottom: 50px;
    }

    .contactus-page .ourClient_wrapper {
        padding-top: 0px;
    }

    .contactus-page .contactUs::after {
        background-size: cover;
        top: -15px;
    }   

}