/* .software .all-stats .stats__box:nth-of-type(6) {
    width: 201px;
    height: 120px;
} */


.software .all-clouds {
    background-image: url(../../../images/software/HeaderSoftware.svg);
    background-position: 0px 150px;
}

.software .migrate .firstImg {
    left: 60%;
    bottom: 0%;
}

.software .dataChallenge .data__container_left::before {
    height: 94%;
}

.software .all-clouds .softwareHeaderImg1,
    .software .all-clouds .softwareHeaderImg2 {
        display: none;
    }

    .software .dataChallenge {
        padding-top: 100px;
    }

@media screen and (max-width:1180px) {

    .software .dataChallenge .data__container_left::before {
        height: 90%;
    }

    .software .dataChallenge {
        padding-top: 50px;
    }

}

@media screen and (max-width:767px) {

    .software .migrate .firstImg {
        left: 0;
    }

    .software .dataChallenge .data__container_left::before {
        height: 94%;
    }

    .software{
        overflow-x: hidden;
    }

    .software .all-clouds{
        position: relative;
        background: unset;
    }

    .software .migrate::before {
        display: none;
    }

    .software .hori__banner {
        padding-bottom: 0;
    }

    .software .migrate .container {
        padding-top: 50px;
    }

    .software .service__tabs.heroTabs {
        margin-top: 20px;
    }

    .software .all-clouds .softwareHeaderImg1 {
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: contain;
        right: 15px;
        bottom: 25%;    
        background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
        padding: 10px;
        border-radius: 50%;
        display: block;
    }

    .software .all-clouds .softwareHeaderImg2 {
        position: absolute;
        width: 40px;
        height: 40px;
        background-size: contain;
        left: 10px;
        bottom: 30%;
        background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
        padding: 10px;
        border-radius: 50%;
        display: block;
    }
}

@media screen and (max-width: 390px){
.software .dataChallenge .data__container_left::before {
    height: 91%;
}

}