.breadcrum {
    display: flex;
    color: #000;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .breadcrum a {
    color: #000;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    /* 157.143% */
  }