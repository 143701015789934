.faq {
    background: #EDF8FF;
    padding: 1px 0;
    margin-bottom: 100px;
    margin-bottom: 0;
    position: relative;
    background-image: url(../../images/dots.png);
}

.faq .container {
    flex-direction: column;
}

.faq h3 {
    font-size: 36px;
    font-weight: 700;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
}

.faq p {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
}

.faq .faq__card {
    padding: 30px 20px;
    display: flex;
    gap: 10px;
    width: 48.7%;
    z-index: 2;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    height: auto;
}

.faq__card_title {
    font-size: 16px;
    display: flex;
    gap: 5px;
    font-weight: 600;
}

.faq__title p{
    margin-top: 20px;
}

.faq__container {
    display: flex;
    justify-content: space-between;
    row-gap: 30px;
    flex-wrap: wrap;
    margin: 100px 0;
    position: relative;
}

.faq__container p {
    text-align: left;
    margin-top: 10px;
    margin-left: 20px;
}

.faq__card_arrow {
    background-color: #FFF;
    min-width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #BB2525;
    border: 0.5px solid #BB2525;
    border-radius: 50%;
    align-self: baseline;
}

.faq__card_arrow.active {
    transform: rotate(90deg);
    color: #fff;
    background: #BB2525;
}

.faq__card_desp {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;
}

.faq .que6 {
    display: none;
}

.faq .icons__gradient{
    z-index: 1;
    left: 30%;
    rotate: 110deg;
    filter: blur(270px) !important;
}

.faq .h100{
    height: 100%;
}

@media screen and (max-width: 1180px) {

    .faq__container {
        padding: 0 20px;
        margin-top: 50px;
    }

    .faq__container p {
        margin: 0;
        padding-left: 20px;
    }

    .faq .container {
        margin-top: 50px;
    }

}

@media screen and (max-width: 767px) {

    .faq__container {
        flex-direction: column;
        margin: 50px 0;
        margin-bottom: 0;
    }

    .faq .container  {
        margin: 50px 0;
    }

    .faq .faq__card {
        width: 100%;
    }

    .faq p {
        padding: 0 20px;
    }
}