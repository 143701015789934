.simple__banner {
    padding: 60px 0;
    position: relative;
    background: linear-gradient(180deg, #16213E 0%, #371010 100%);
    color: #fff;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    min-height: 360px;
}

.simple__banner .simple-banner-bg {

    position: absolute;
    bottom: 0;
}

.simple__banner::after {
    content: '';
    position: absolute;
    background: url(../../images/simpleabanner2.svg);
    background-size: cover;
    right: 0px;
    width: 600px;
    top: 0;
    height: 100%;
    background-repeat: no-repeat
}

.simple__banner .container {
    justify-content: space-between;
    align-items: center;
    max-width: 1170px;
    margin: 0 auto;
    position: relative;
    z-index: 2;
    width: 100%;
}

.simple__banner .simple__left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 60%;
    position: relative;
}

.simple__banner .simple__left h3 {
    color: #FFF;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px
}

.simple__banner p {
    color: #FFF;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
}

.simple__banner a {
    background-color: #BB2525;
    padding: 15px 30px;
    color: #FFF;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    height: fit-content;
    position: relative;
    border-radius: 10px;
    /* animation: pulse-effect 1s infinite; */
}


.simple__banner a::after {
    background-color: #BB2525;
    bottom: -5px;
    content: "";
    display: block;
    height: 6px;
    left: 0;
    position: absolute;
    transform: scaleX(0);
    transition: transform .3s ease-out, border-color .3s ease-out;
    width: 100%;
}

.red-text {
    color: #FF8787 !important;
}

.simple__banner .common-btn{
    color: #BB2525;
    background: linear-gradient(96.82deg, #FFE2E2 15.91%, #DE5959 235.2%);
    transition: .5s background ease-in;
}

.simple__banner .common-btn:hover {
    color: #fff;
    background: #BB2525;
}

@media screen and (max-width:1180px) {

    .simple__banner {
        padding: 40px 0;
    }

    .simple__banner .container {
        padding: 20px;
        justify-content: space-around;
    }

    /* .simple__banner::before{
        width: 100%;
    } */

    /* .simple__banner::after{

        width: 100%;
    } */

    .simple__banner a {
        font-size: 14px;
        padding: 15px 20px;
    }

    .simple__banner a.red-btn {
        padding: 16px 24px;
    }

    .simple__banner .simple__left h3 {
        color: #FFF;
        font-size: 26px;
    }

    .simple__banner p {
        font-size: 15px;
        line-height: 25px;
    }
}

@media screen and (max-width:767px) {
    .simple__banner {
        padding: 50px 0;
    }

    .simple__banner .container {
        flex-direction: column;
        align-items: start;
        padding: 0px 20px;
        gap: 20px;
    }

    .simple__left .red-text{
        margin-bottom: 20px;
    }

    .simple__banner .simple__left {
        max-width: 100%;
        gap: 0;
    }

    .simple__banner .simple-banner-bg {
        background-position: left bottom;
        display: none;
        /* width: 320px; */
        /* display: none; */
    }

    
}