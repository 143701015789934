.hero__banner {
    display: flex;
    justify-content: space-between;
    max-width: 1180px;
    margin: 0px auto;
}

.hero__banner_wrapper {
    background: #EDF8FF;
    padding: 40px 0;
    position: relative;
    margin-top: 50px;
}

.hero__banner_wrapper::before {
    content: '';
    background: url('../../images/top-header-bg.svg');
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 1180px;
}

.hero__banner_right,
.hero__banner_left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}

.hero__banner_right {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.hero__banner_right img {
    width: 460px;
    z-index: 3;
}

.hero__banner_left {
    max-width: 510px;
}

.hero__banner h3 {
    color: #16213E;
    font-size: 36px;
    font-weight: 700;
    line-height: 40px;
    /* 111.111% */
    position: relative;
}

.hero__banner strong,
.hero__banner p {
    padding: 0 20px;
}

.hero__banner strong{
    font-weight: 600;
}

.hero__banner p {
    padding-bottom: 20px;
}

.hero__banner h3 span {
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
}

.hero__banner h3 span.redBold {
    color: #BB2525;
    font-weight: 700;
}

.hero__banner .red-btn{
    margin-left: 20px;
    margin-top: 10px;
}

.red-btn {
    background: linear-gradient(0deg, #BB2525, #BB2525), linear-gradient(97.08deg, #D84A4A -12.21%, #A41B1B 102.49%);
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    color: #fff;
    width: -moz-fit-content;
    width: fit-content;
    box-shadow: 5px 1px 8px 0px #0000001C;
    padding: 15px 25px;
    position: relative;
    overflow: hidden;
    border-radius: 11px;
    transition: all .4s;
}

.red-btn {
    cursor: pointer;
    outline: none;
}

.red-btn:before {
    background: #fff;
    content: "";
    height: 305px;
    left: -75px;
    opacity: .2;
    position: absolute;
    top: -150px;
    width: 50px;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
    transform: rotate(35deg);
    z-index: 7;
}
  
  .red-btn:hover{
    background-color: #fff;
    box-shadow: 0px 10px 30px #f97d9fc2;
  }
  
  .red-btn:hover::before {
    left: 120%;
    transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
  }
  

.all-stats {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    gap: 20px;
    z-index: 5;
}

.hero__banner .stats__box {
    border-radius: 25px;
    border: 0.7px solid #BB2525;
    background: #FFF;
    box-shadow: 5px 4px 9px 0px rgba(0, 0, 0, 0.10);
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px;
    padding: 20px;
    width: 29%;
    border-radius: 25px;
    border: 0.8px solid #BB2525;
    background: linear-gradient(308deg, #FFF 9.43%, #FFF5F5 90.32%);
    box-shadow: -1px 7px 9px 0px rgba(0, 0, 0, 0.03);
}

.stats__box.four {
    width: 45%;
}

.hero__banner .stats__box span {
    color: #BB2525;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 29px;
}

@media screen and (max-width:1180px) {

    .hero__banner_wrapper {
        padding-top: 1px;
        margin-top: 0;
    }

    .hero__banner {
        margin: 20px auto;
        gap: 50px;
    }

    .hero__banner_right,
    .hero__banner_left {
        width: 100%;
        margin: 0 auto;
    }

    .hero__banner_right img {
        width: 340px;
    }

    .hero__banner .stats__box {
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 15px;
        padding: 10px;
        width: 45%;
        height: unset;
    }

    .all-stats {
        gap: 10px;
    }


    .hero__banner .stats__box span {
        color: #BB2525;
        font-size: 18px;
    }

    .hero__banner .stats__box {
        font-size: 14px;
    }

}

@media screen and (max-width:767px) {

    .hero__banner {
        flex-direction: column;
        margin: 50px auto;
        gap: 40px;
        padding-bottom: 50px;
        margin-bottom: 0;
        padding-top: 30px;
    }

    .hero__banner h3 span {
        font-size: 28px;
    }

    .hero__banner_left {
        gap: 0;
    }

    .hero__banner h3 {
        font-size: 27px;
        line-height: 32px;
        padding-right: 0;
    }

    .hero__banner .stats__box {
        padding: 10px 15px;
        line-height: 20px;
    }

}