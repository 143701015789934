.cyberSecurity .dataChallenge .data__container_left::before {
    height: 95%;
}

/* .all-security{
    background: url(../../../images/security-header.svg) no-repeat top;
    background-size: contain;
} */


.cyberSecurity .hero__banner h3 span {
    font-size: 36px;
}

.cyberSecurity .stats__box:nth-of-type(1) {
    width: 192px;
    height: 142px;
}

.cyberSecurity .stats__box:nth-of-type(2) {
    width: 242px;
    height: 120px;
    margin-top: auto;
}

.cyberSecurity .stats__box:nth-of-type(3) {
    width: 234px;
    height: 154px;

}

.cyberSecurity .stats__box:nth-of-type(4) {
    width: 244px;
    height: 153px;
    margin-bottom: auto;
}

.cyberSecurity .heroTabs__container .bubble {
    left: unset;
    width: 200px;
    height: 200px;
    top: 60%;
    right: 0;
    z-index: 2;
}


.cyberSecurity .all-security .main {
    position: relative;
}

.cyberSecurity .all-security .main::before {
    content: "";
    width: 68px;
    height: 68px;
    background: url("../../../images/all-security-icon-right.svg");
    position: absolute;
    right: -90px;
    top: -5px;
    background-size: contain;
    z-index: -1;
}

.cyberSecurity .all-security .main::after {
    content: "";
    width: 69px;
    height: 72px;
    background: url("../../../images/all-security-icon-left.svg");
    position: absolute;
    left: -93px;
    top: -10px;
    background-size: contain;
    z-index: -1;
}



@media screen and (max-width:1180px) {

    .cyberSecurity .all-stats .stats__box:nth-of-type(1),
    .cyberSecurity .all-stats .stats__box:nth-of-type(2),
    .cyberSecurity .all-stats .stats__box:nth-of-type(3),
    .cyberSecurity .all-stats .stats__box:nth-of-type(4),
    .cyberSecurity .all-stats .stats__box:nth-of-type(5) {
        width: 45%;
        height: fit-content;
    }

    .cyberSecurity .heroTabs__container .bubble {
        width: 120px;
    }

    .cyberSecurity .all-security {
        background-position: 0 52%;
    }

    .cyberSecurity .dataChallenge .data__container_left::before {
        height: 91%;
    }

}

@media screen and (max-width:767px) {

    .cyberSecurity{
        overflow-x: hidden;
    }

    .cyberSecurity .dataChallenge .data__container_left::before {
        height: 93%;
    }

    .cyberSecurity .heroTabs__container .bubble {
        z-index: 2;
    }

    .cyberSecurity .hero__banner h3 span {
        font-size: 28px;
        line-height: 32px;
    }
    
    .cyberSecurity .all-security .main::before {
        width: 46px;
        height: 46px;
        right: -0;
        top: 50px;
        background-size: contain;
    }

    .cyberSecurity .all-security .main::after {
        width: 50px;
        height: 50px;
        position: absolute;
        left: -0;
        top: 50px;
        background-size: contain;
    }

    .cyberSecurity .simple__banner .simple__left h3 {
        margin-bottom: 0;
    }

}