.security__cards {
    padding-top: 50px;
    position: relative;
    overflow: hidden;
    background-color: #EDF8FF;
}

.security__banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1180px;
    margin: 0px auto;
    margin-bottom: 50px;
    flex-direction: column;
}

.security__banner_left {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.security__banner_left .contactBtn {
    margin-bottom: 50px;
}

.security__banner_right {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    position: relative;
    width: 100%;
    top: -50px;
    row-gap: 50px;
    padding-left: 20px;
}

/* .security__banner_right::before {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    left: -10%;
    top: 26%;
}

.security__banner_right::after {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    right: 0%;
    top: 26%;
} */

.lockBg {
    position: absolute;
    bottom: 10%;
    left: 2%;
}

.security__banner h3 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

.contactBtn {
    background-color: #BB2525;
    padding: 10px 30px;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    height: -moz-fit-content;
    height: fit-content;
    position: relative;
    border-radius: 10px;
    /* animation: pulse-effect 1s infinite; */
    white-space: pre;
    z-index: 2;
}


.security__banner .security__card {
    display: flex;
    padding: 20px;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 58px 52px -31px rgba(0, 59, 102, 0.11);
    z-index: 5;
}

.security__banner_left p {
    padding: 20px;
    max-width: 462px;
}

.security__banner .security__card:nth-child(1) {
    width: 426px;
    margin-top: auto;
    gap: 20px;
}

.security__banner .security__card:nth-child(2) {
    width: 318px;
    margin-top: auto;
    flex-direction: column;
}

.security__banner .security__card:nth-child(3) {
    width: 318px;
    flex-direction: column;
    margin-top: auto;
}

.security__banner .security__card:nth-child(4) {
    width: 340px;
    margin-bottom: auto;
    gap: 10px;
}

.security__banner .security__card:nth-child(5) {
    margin-bottom: auto;
    width: 398px;
    gap: 20px;
}

.security__banner .security__card:nth-child(6) {
    width: 318px;
    margin-bottom: auto;
    flex-direction: column;
}


.security__card .security__content {
    display: flex;
    gap: 23px;
    padding-bottom: 34px;
    padding-top: 23px;
    flex-direction: column;
}

.security__card-img{
    display: flex;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    border-radius: 50%;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    justify-content: center;
    align-items: center;
}

.security__card img {
    max-width: 40px;
    height: 40px;
    width: 100%;
}

.security__card h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
}

.service__tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1180px;
    margin: 50px auto;
    gap: 20px;
}

.redirect{
    position: absolute;
    height: 30px;
    width: 30px;
    right: 70px;
    top: 40px;
}

.redirect svg{
   width: 100%;
   height: 100%;
   fill: #BB2525;
   opacity: 0;
   transition: .5s all ease-in-out;
}


.security__card:hover .redirect svg{
    fill: #BB2525;
   opacity: 1;
}

/* .blue-arrow{
    position: absolute;
    top: 0;
} */

@-moz-document url-prefix() {
    .blueCircle {
        background: #9ad8ecc4 !important;
    }
}

@media screen and (max-width:1180px) {

    .security__banner {
        padding: 20px;
        margin-bottom: 0;
    }

    .security__banner_right {
        justify-content: space-between;
        top: 0;
        row-gap: 30px;
        padding-left: 0;
        align-items: stretch;
    }

    .cyberSecurity .security__banner .security__card ,
.security__card .security__content {
    gap: 10px;
    padding-top: 0;

}


    .security__banner .security__card:nth-child(1) {
        width: 49%;
        height: auto;
        margin: 0;
        gap: 20px;
    }

    .security__banner .security__card:nth-child(2) {
        width: 49%;
        height: auto;
        margin-top: 0;
        flex-direction: column;
    }

    .security__banner .security__card:nth-child(3) {
        width: 49%;
        height: auto;
        flex-direction: column;
        margin-top: 0;
    }

    .security__banner .security__card:nth-child(4) {
        width: 49%;
        height: auto;
        margin-bottom: 0;
        gap: 10px;
    }

    .security__banner .security__card:nth-child(5) {
        margin-bottom: unset;
        width: 49%;
        height: auto;
        gap: 20px;
    }

    .security__banner .security__card:nth-child(6) {
        width: 49%;
        height: auto;
        margin: 0;
        flex-direction: column;
    }

    .cyberSecurity .security__banner .security__card {
        padding: 20px;
        width: 49%;
        gap: 20px;
        flex-direction: column;
    }


}


@media screen and (max-width:767px) {

    .security__banner h3.main, .security__banner h3.main .redTitle {
        font-size: 28px;
        line-height: 32px;
    }

    .security__banner_right,
    .security__banner_left {
        width: 100%;
    }

    .security__banner {
        flex-direction: column;
        margin: 0;
        padding-bottom: 70px;
    }

    .security__banner_left {
        gap: 10px;
        margin: 0;
        flex-direction: column;
        margin-bottom: 30px;
        align-items: flex-start;
    }

    .security__card .security__content {
        gap: 10px;
        padding: 0px;
    }

    .security__banner_left p {
        padding: 0 20px;
    }

    .cyberSecurity .security__banner .security__card {
        padding: 20px;
        width: 100%;
        gap: 20px;
        flex-direction: row;
    }

    .security__banner_left .contactBtn {
        margin: 20px;
    }

    .security__cards {
        padding-top: 0;
    }

    .lockBg {
        left: -100px;
    }

    .redirect{
        height: 20px;
        width: 20px;
        right: 0px;
    }
}