/* scroll to top button */

.icon-circle{
    width: 60px;
    height: 60px;
    padding: 10px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    z-index: 11;
    background: #BB2525;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
  
  .top-btn-style {
    cursor: pointer;
    width: 100%;
    height: 100%;
    
    z-index: 9;
    right: 30px;
    bottom: 30px;
   
    display: flex;
    align-items: center;
    justify-content: center;
    animation: move 1.7s infinite alternate;
    transition: background-color .35s ease-in-out, box-shadow .35s ease-in-out, transform .35s ease-in-out;
    color: #fff;
  }
  
  .top-btn-style:hover {
    animation: none;
  
  }

@media screen and (max-width: 767px) {

    .icon-circle{
        width: 50px;
        height: 50px;
    }

}