.OurSpecialities__wrapper {
    background-color: #EDF8FF;
    padding: 100px 0;
}

.OurSpecialities::after {
    background: url('../../images/clouds.svg');
    content: '';
    width: 500px;
    height: 300px;
    position: absolute;
    top: -157px;
    background-repeat: no-repeat;
}

.OurSpecialities {
    margin: 0 auto;
    max-width: 1180px;
    position: relative;
}

.OurSpecialities::before {
    content: '';
    position: absolute;
    background: url(../../images/animated-line.svg) no-repeat center;
    width: 100%;
    background-size: contain;
    height: 90%;
    top: 10%;
}

.OurSpecialities__title {
    display: flex;
    justify-content: space-between;
}

.OurSpecialities__title h3 {
    color: #000;
    font-size: 36px;
    font-weight: 700;
}

.OurSpecialities__title .subTitle {
    max-width: 384px;
    margin-left: 50px;
    margin-top: 50px;
}

.OurSpecialities__title .subTitle span {
    color: #BB2525;
    position: relative;
}

.OurSpecialities__title .subTitle span::after {
    content: '';
    width: 3px;
    height: 70px;
    background: #16213E;
    position: absolute;
    left: -10.7%;
    top: -60px;
}

.OurSpecialities__title .subTitle span::before {
    content: '';
    width: 5px;
    height: 5px;
    background-color: #BB2525;
    border-radius: 50%;
    position: absolute;
    left: -11%;
    z-index: 1;
    top: 8px;
}

.OurSpecialities .red-btn {
    height: fit-content;
    z-index: 2;
}

.OurSpecialities__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 40px;
    margin: 0 50px;
    margin-top: 50px;
}

.OurSpecialities__card {
    padding: 30px;
    display: flex;
    gap: 10px;
    flex-direction: column;
    width: 47%;
    backdrop-filter: blur(80px);
    z-index: 2;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    cursor: pointer;
}

.OurSpecialities__card h4{
    font-size: 20px;
}

.OurSpecialities__wrapper .icons__gradient{
    z-index: 1;
    filter: blur(270px) !important;
}



@media screen and (max-width:1180px) {

    .OurSpecialities__wrapper {
        padding-bottom: 50px;
    }

    .OurSpecialities__title {
        padding: 0 20px;
    }

    .OurSpecialities__container {
        margin: 40px 65px;
        gap: 30px;
    }

    .OurSpecialities::before {
        width: 90%;
        background-size: contain;
        height: 100%;
        top: 5%;
        padding: 0 20px;
        margin: 0 20px;
        display: none;
    }

    .OurSpecialities__title .subTitle span::after ,
    .OurSpecialities__title .subTitle span::before {
        display: none;
    }

    .OurSpecialities__card {
        padding: 20px;
    }

}

@media screen and (max-width: 767px) {

    .OurSpecialities__container {
        margin: 0;
        padding: 20px;
    }

    .OurSpecialities__card {
        width: 100%;
    }

    .OurSpecialities__wrapper {
        padding: 50px 0;
    }

    .OurSpecialities::before {
        display: none;
    }

    .OurSpecialities::after {
        top: -150px;
    }

    .OurSpecialities__title .subTitle {
        max-width: unset;
        width: 100%;
        margin: 30px 0;
        margin-bottom: 10px;
    }

    .OurSpecialities__title .subTitle span::before,
    .OurSpecialities__title .subTitle span::after {
        display: none;
    }

    .OurSpecialities__title {
        flex-direction: column;
    }

    .OurSpecialities .red-btn {
        margin: 20px 0;
    }


}