.projects {
    overflow-x: hidden;
}

.projects .migrate {
    background: #EDF8FF;
}

.projects .migrate::after {
    content: '';
    background: url('../../images/top-header-bg.svg');
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 1180px;
}

.projects .migrate .title-container {
    display: none;
}
.projects .migrate .migrate__cards {
    left: 0;
}

.projects .migrate .migrate__card{
    gap: 30px;
    position: relative;
}

.migrate__card:hover .redirect svg{
    fill: #BB2525;
   opacity: 1;
}

.migrate__card .redirect {
    top: 5px;
    right: 20px;
}

.projects .migrate .container {
    padding-top: 0;
}

.projects .migrate__card img{
    min-width: 40px;
    height: 40px;
    align-self: baseline;

}

.projects .migrate .migrate__card  a{
    position: absolute;
    width: 100%;
    height: 100%;
}  

.projects .migrate::before {
    display: none;
}


@media screen and (max-width:767px){
    .migrate__card .redirect {
        top: 20px;
        right: 40px;
    }
}