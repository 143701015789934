
/* top banner  */

.about{
    overflow: hidden;
}

.about .hero__banner .banner__circle1{
    border-radius: 50%;
    position: absolute;
    width: 1200px;
    height: 1200px;
    top: -510px;
    left: -100px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: .6;
}

.about .all-stats {
    display: none;
}

.about .hero__banner_right img {
    width: unset;
    margin-bottom: -70px;
}

.about .hero__banner .banner__circle2{
    border-radius: 50%;
    position: absolute;
    width: 1000px;
    height: 1000px;
    left: 60px;
    top: -440px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: .8;
}

.about .hero__banner .banner__circle3{
    border-radius: 50%;
    position: absolute;
    width: 800px;
    height: 800px;
    left: 200px;
    top: -360px;
    background-color: #fff;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: 1;
}

.about .hero__banner .banner__circle1::after,
.about .hero__banner .banner__circle2::after,
.about .hero__banner .banner__circle3::after{
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    left: 0px;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
}

.about .hero__banner .banner__circle1::after{
    bottom: 270px;
    left: 110px;
}
.about .hero__banner .banner__circle3::after{
    left: 230px;
    bottom: 0;
}

.about .hero__banner h3.main,
.about .hero__banner h3.main span{
    font-size: 45px;
    line-height: 65px;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
}

.about .hero__banner h3 span.redBold,
.about .hero__banner h3.main{
    font-size: 50px;
}

.about .hero__banner_left {
    max-width: 525px;
    z-index: 1;
    width: 51%;
    gap: 10px;
}

.about .hero__banner_wrapper::before {
    content: unset;
}

.about .hero__banner p {
    padding-left: 0;
}

.about .hero__banner_wrapper {
    margin-top: 0;
    padding-top: 100px;
    padding-bottom: 0px;
}

.about .title__small,
.about .redTitle{
    white-space: unset;
}

.about h3.main::before {
    width: 70px;
    height: 3px;
    top: -10px;
}

.about h3.main::after {
    top: 10px;
    width: 100px;
}

.about .hero__banner strong{
    padding: 0;
}

/* second component  */

.about .security__banner h3.main::before,
.about .security__banner h3.main::after {
    display: none;
}

.about .security__banner_left .contactBtn {
    display: none;
}

/* .about .security__banner h3 {
    max-width: 300px;
} */

.about .security__banner_right {
    top: 0;
    padding-left: 0;
    position: relative;
}

.about .security__banner_right::before {
    content: '';
    background: url('../../images/blue-arrow.svg') no-repeat center;
    background-size: contain;
    width: 85%;
    height: 100%;
    position: absolute;
    top: -69%;
    left: 11px;
    z-index: 5;
}

.about .blueCircle{
    display: none;
}

.about .security__banner .security__card{
    flex-direction: column;
    width: 31%;
    box-shadow: unset;
    margin: 0;
    height: 100%;
    gap: 10px;
    background: transparent;
    border: none;
    border-radius: 0;
    position: relative;
}

.about .security__banner .security__card::after{
    content: '';
    background-color: #94EAFF;
    height: 250px;
    width: 1px;
    position: absolute;
    right: -23px;
    top: 15%;
}

.about .security__banner .security__card:nth-child(3)::after {
    display: none;
}

.about .security__banner .security__card:nth-child(2){
    margin-top: -150px;
}

.about .security__banner .security__card:nth-child(3){
    margin-top: -260px;
}

/* .about .security__card .security__content{
    max-width: 270px;
} */

.security__wrapper{
    background-color: #EDF8FF;
    position: relative;
}

.about .security__cards{
    background: #fff;
    padding-top: 100px;
}

.about .security__wrapper::after,
.about .security__wrapper::before {
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    bottom: -50px;
    right: 40%;
    z-index: 1;
}

.about .security__wrapper::after{
    right: 10%;
    bottom: 10%;
    width: 60px;
    height: 60px;  
}

.about .security__card:nth-child(1)  img{
    height: 20px;
    max-width: 20px;
}
.about .security__card:nth-child(2) img {
    height: 40px;
    max-width: 40px;
}
.about .security__card:nth-child(3) img {
    height: 50px;
    max-width: 50px;
}

.about .lockBg{
    display: none;
}

@media screen and (max-width: 1180px) {

    .about .hero__banner h3.main, .about .hero__banner h3.main span ,
    .about .hero__banner h3 span.redBold, .about .hero__banner h3.main {
        font-size: 36px;
        line-height: 45px;
    }

    .about .hero__banner_wrapper {
        padding: 50px 0;
        padding-top: 100px;
        padding-bottom: 10px;
    }

    .about .hero__banner {
        flex-direction: column;
    }

    .about .hero__banner_right,
    .about .hero__banner_left {
        width: 100%;
    }

    .about .hero__banner_left {
        max-width: unset;
        margin-left: 20px;
    }

 .about .security__wrapper::before {
        right: 30%;
        bottom: 10px;
    }

    .about .ourClient_wrapper {
        padding-top: 50px;
    }

    .security__banner .security__card:nth-child(1) {
        margin-top: -30px;
    }

    .about .security__banner .security__card:nth-child(2) {
        margin-top: -100px;
    }

    .about .security__banner .security__card:nth-child(3) {
        margin-top: -157px;
    }

    .about .security__banner_right:before {
        top: -77%;
        width: 85%;
        left: 20px;
        display: none;
    }

    .about .hero__banner h3.main {
        gap: 6px;
    }

.about .security__banner .security__card::after{
    top: 0;
}

}

@media  only screen and (max-width: 900px)  {

    .about .security__banner_right::before {
        display: none;
    }

}

@media screen and (max-width: 767px) {

    .about .security__wrapper::after,
    .about .security__wrapper::before {
            display: none;
    }

    .about .hero__banner_left {
        width: 100%;
        gap: 0;
    }

    .about .hero__banner {
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
    }

    .about .redTitle {
        white-space: unset;
    }

    .about .hero__banner h3.main, .about .hero__banner h3.main span ,
    .about .hero__banner h3 span.redBold, .about .hero__banner h3.main {
        font-size: 28px;
        line-height: 35px;
    }

    .about .hero__banner h3.main {
        gap: 0;
    }

    .about .security__banner .security__card {
        width: 90%;
        padding: 0 20px;
    }

    .about .security__banner .security__card::after {
        left: 0;
        top: 0;
        height: 100%;
    }

    .about .security__banner_right {
        justify-content: center;
    }

    .about .hero__banner .banner__circle1,
    .about .hero__banner .banner__circle2,
    .about .hero__banner .banner__circle3{
        display: none;
    }

    .about .security__cards {
        padding-bottom: 50px; 
        padding-top: 0;
    }

    .about .security__banner .security__card:nth-child(2) ,
    .about .security__banner .security__card:nth-child(3) {
        margin: 0;
    }

    .about .hero__banner_right img {
        width: 340px;
        margin-bottom: -24px;
    }

    .about .security__banner_left {
        margin-bottom: 0;
    }

    .about .security__banner .security__card:nth-child(3)::after {
        display: flex;
    }

    .about .ourClient,
    .about .ourClient_wrapper {
        padding-top: 0;
    }

}