

.digital .all-clouds {
    background-image: url(../../../images/digital/HeaderDigital.svg);
    background-position: 0px 140px;
}

.digital  .all-stats .stats__box:nth-of-type(4) {
    margin-bottom: auto;
}


.digital .hori__banner .hori__card {
    height: auto;
}

.digital .hori__banner_left p {
    padding-left: 20px;
    margin-bottom: 30px;
}

.digital .migrate::before  {
    display: none;
 }

.digital .all-clouds .digitalHeaderImg1 ,
    .digital .all-clouds .digitalHeaderImg2 {
display: none;
position: absolute;
    }


    @media screen and (max-width:1180px){
        .digital .title__banner h2 {
            text-align: center;
        }
    }

@media screen and (max-width:767px) {
    .digital .security__banner .security__card {
    padding: 20px;
    width: 100%;
    gap: 20px;
    flex-direction: row;
}

.digital{
    overflow-x: hidden;
}

.digital .migrate .container {
    padding-top: 50px ;
    padding-bottom: 0;
}


.digital .all-clouds {
    background-image: url(../../../images/digital/HeaderDigital.svg);
    background: unset;
    position: relative;
}

.digital .all-clouds .digitalHeaderImg1 {
    position: absolute;
    width: 50px;
    height: 50px;
    object-fit: contain;
    right: 15px;
    bottom: 20%;    
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    padding: 10px;
    border-radius: 50%;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.digital .all-clouds .digitalHeaderImg2 {
    position: absolute;
    width: 50px;
    height: 50px;
    object-fit: contain;
    left: 10px;
    bottom: 20%;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    padding: 10px;
    border-radius: 50%;
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
}

.digital .all-clouds .digitalHeaderImg1 img,
    .digital .all-clouds .digitalHeaderImg2 img{
height: 100%;
    }


}