/* .all-analytics{
    background: url(../../../images/analytics-header.svg) no-repeat top;
    background-size: contain;
} */

.analtics .stats__box:nth-of-type(1) {
    width: 192px;
    height: 132px;
    padding-left: 31px;
}

.analtics .stats__box:nth-of-type(2) {
    width: 254px;
    margin-top: auto;
    height: 100px;
}

.analtics .stats__box:nth-of-type(3) {
    width: 168px;
    height: 160px;

}

.analtics .stats__box:nth-of-type(4) {
    width: 244px;
    height: 126px;
    margin-bottom: auto;
}

.analtics .hero__banner h3 span {
    font-size: 36px;
}

.analtics .all-analytics {
    background: url(../../../images/all-analytics.png) no-repeat top;
    background-size: contain;
    background-position: 10% 70%;
}

.analtics .hori__banner .hori__card {
    height: fit-content;
}

@media screen and (max-width:1180px) {

    .analtics .all-stats .stats__box:nth-of-type(1),
    .analtics .all-stats .stats__box:nth-of-type(2),
    .analtics .all-stats .stats__box:nth-of-type(3),
    .analtics .all-stats .stats__box:nth-of-type(4),
    .analtics .all-stats .stats__box:nth-of-type(5) {
        width: 45%;
        height: fit-content;
    }

    .analtics .simple__banner p {
        margin-bottom: 20px;
    }

}

@media screen and (max-width:767px) {

    .analtics{
        overflow-x: hidden;
    }
    
    .analtics .all-analytics {
        background-position: 0px 50%;
        background: unset;
        position: relative;
    }

    .analtics .all-analytics::before {
        position: absolute;
        content: '';
        background: url('/src/images/analytics/dataHeader1.svg') no-repeat;
        width: 50px;
        height: 50px;
        background-size: contain;
        right: -10px;
        bottom: 20%;
    }

    .analtics .all-analytics::after {
        position: absolute;
        content: '';
        background: url('/src/images/analytics/dataHeader2.svg') no-repeat;
        width: 40px;
        height: 40px;
        background-size: contain;
        left: 10px;
        top: 40%;
    }

    .analtics .hori__banner .hori__card {
        height: fit-content;
    }


    .analtics .dataChallenge .data__container_left::before {
        height: 90%;
    }

    .analtics .hero__banner h3 span {
        font-size: 28px
    }

    .analtics .simple__banner .simple__left h3 {
        margin-bottom: 0;
    }
}