@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

body {
  overflow-x: hidden;
}

ul,li{
  list-style: none;
}

a{
  text-decoration: none;
}

p{
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px; 
}

.redTitle{
  color: #BB2525;
  font-size: 36px;
  font-weight: 400;
  line-height: 42px;
}

button{
  cursor: pointer;
}

@keyframes pulse-effect {
  0% {
      box-shadow: 0 0 0 0 rgba(255, 0, 0, .351);
  }

  100% {
      box-shadow: 0 0 0 15px #f000;
  }
}

@media screen and (max-width:1180px)  {

  p{
    color: #000;
    font-size: 12px;
    line-height: 20px;
  }
}