.testimonial__wrapper {
    background-color: #fff;
    position: relative;
}

.testimonial__wrapper::before{
    content: '';
    background: url('../../images/top-header-bg.svg');
    position: absolute;
    top: 0px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
    transform: rotate(180deg);
    margin: 0 auto;
    max-width: 1440px;
    display: none;
}

.testimonial {
    max-width: 1280px;
    margin: 0 auto;
    display: flex;
    position: relative;
    padding-top: 117px;
    padding-bottom: 110px;
    overflow-y: clip;
    position: relative;
}

.testimonial .card__desp{
    min-height: 340px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

/* .testimonial .card__desp p{
    text-align: center;
} */

.qoutes{
    width: 120px;
    height: 120px;
    position: absolute;
    left: 22%;
    fill: #EDF8FF;
}

.testimonial h4 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    min-height: 72px;
}

.testimonial h4 span:nth-child(1) {
    font-weight: 600;
}

.testimonial .testimonial__l {
    width: 50%;
}

.testimonial .testimonial__left {
    width: 370px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;
    z-index: 5;
}

.testimonial .controls {
    border-top: 1px solid #BB2525;
    width: 200px;
    margin-top: 30px;
    padding-top: 30px;
    display: flex;
    justify-content: space-evenly;
}

.testimonial .control__circle {
    width: 55px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DBDBDB;
    border-radius: 50%;
    font-size: 36px;
    cursor: pointer;
}

.testimonial .control__circle.active {
    color: #BB2525;
    background: #F5DEDE;
}

.testimonial .testimonial__right {
    width: 70%;
    position: relative;
    display: flex;
    gap: 20px;
    padding: 50px 20px;
    scrollbar-width: none;
    transition: all .4s cubic-bezier(1, 0.05, 1, 0.15);
    z-index: 4;
}

.testimonial .icons__gradient{
    z-index: 2;
}

.testimonial .card {
    min-width: 318px;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    padding: 30px;
    position: relative;
    padding-top: 70px;
    opacity: 1;
    transition: all .4s;
    transition-delay: 0.4s;
}

.testimonial .card.inactive {
    transition-delay: 0s;
    position: relative;
    transform: translateY(100px);
    opacity: 0;
}

.testimonial .card .card__img {
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    top: -50px;
    right: 20%;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
}

.testimonial .card h4 {
    font-size: 14px;
    font-weight: 600;
    line-height: 26px;
    text-align: left;
    position: relative;
    margin-top: 30px;
    padding-top: 20px;
}

.testimonial .card h4::after {
    background-color: #BB2525;
    height: 2px;
    width: 100px;
    position: absolute;
    content: '';
    left: 0;
    top: 10px;
}

.controls.desk{
    display: flex;
}

.controls.mob{
    display: none;   
}

@media screen and (min-width:767px) {
    .testimonial .controls .control__circle:hover{
        background-color: #ff7b7b;
    }
}

@media screen and (max-width:767px) {

    .testimonial {
        flex-direction: column;
        padding-top: 0;
        padding-bottom: 50px;
    }

    .testimonial .card__desp {
        min-height: unset;
    }

    .testimonial .testimonial__left,
    .testimonial .testimonial__right {
        width: 100%;
    }

    .testimonial .testimonial__left {
        padding: 100px 20px;
        padding-bottom: 50px;
        max-width: 370px;
        margin: 0 auto;
    }

    .testimonial__left p{
        margin-top: 20px;
    }

    .qoutes{
        top: 60px;
        left: 50%;
        height: 80px;
        width: 80px;
    }

    .testimonial .card {
        padding: 20px;
        padding-top: 70px;
    }

    .controls.desk{
        display: none;
    }
    
    .controls.mob{
        display: flex;   
        margin-top: 0;
        padding-top: 0;
        margin: 0 auto;
        border-top:unset ;
    }

    .testimonial .testimonial__right {
        padding-bottom: 40px;
    }

}