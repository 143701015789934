/* top banner  */

.home {
    overflow: hidden;
}

.home .hero__banner .banner__circle2 {
    border-radius: 50%;
    position: absolute;
    width: 1020px;
    height: 1020px;
    left: -201px;
    top: -233px;
    background: #F5FBFF;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: .8;
    z-index: 1;
}

.home .hero__banner .banner__circle1 {
    border-radius: 50%;
    position: absolute;
    width: 1300px;
    height: 1300px;
    top: -367px;
    left: -370px;
    background-color: #EDF8FF;
    box-shadow: 0px 0px 52px 0px #0000000A;
    z-index: 1;
}

.home .hero__banner .banner__circle3 {
    border-radius: 50%;
    position: absolute;
    width: 790px;
    height: 790px;
    left: -70px;
    top: -130px;
    background-color: #FDFEFF;
    box-shadow: 0px 0px 52px 0px #0000000A;
    opacity: 1;
    z-index: 3;
}

.home .hero__banner_img {
    width: 525px;
    z-index: 3;
    background: linear-gradient(339.08deg, #27BDDE -7.29%, #96ECFF 30.2%, #E2EAFF 76.41%, #CBD5F1 96.65%);
    border-radius: 50%;
    height: 525px;
    box-shadow: 40px 47px 40px 7px #00000014;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home .hero__banner .banner__circle2::after,
.home .hero__banner .banner__circle3::before,
.home .hero__banner .banner__circle3::after {
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 150px;
    height: 150px;
    display: flex;
    border-radius: 50%;
    left: 650px;
    bottom: 0px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
}

.home .hero__banner .banner__circle3::before {
    bottom: 50px;
    left: 40px;
    width: 150px;
    height: 150px;
}

.home .hero__banner .banner__circle3::after {
    left: 60px;
    top: 120px;
    width: 60px;
    height: 60px;
}

.home .hero__banner_img img {
    width: 400px;
}

.home .hero__banner h3.main,
.home .hero__banner h3.main span {
    font-size: 45px;
    line-height: 65px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 10px;
    padding-left: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.home .hero__banner h3 span.redBold,
.home .hero__banner h3.main {
    font-size: 55px;
}

.home .hero__banner_left {
    max-width: 562px;
    z-index: 1;
    width: 51%;
    gap: 10px;
    z-index: 2;
}

.home .hero__banner_left .red-btn{
    margin-top: 12px;
    margin-left: 0;
}

.home .hero__banner_wrapper::before {
    content: unset;
}

.home .hero__banner p {
    padding: 0;
}

.home .hero__banner_wrapper {
    margin-top: 0;
    padding-top: 150px;
    padding-bottom: 170px;
}

.home .title__small,
.home .redTitle {
    font-weight: 600;
    color: #16213E;
}

.home h3.main::before {
    width: 70px;
    height: 3px;
    top: -30px;
}

.home h3.main::after {
    top: -15px;
    width: 100px;
}


/* second component  */

.home .security__banner h3.main::before,
.home .security__banner h3.main::after {
    display: none;
}

.home .security__banner_left .contactBtn {
    display: none;
}

.home .security__banner h3 {
    max-width: 300px;
    font-weight: 400;
    color: #16213E;
}

.home .security__banner_right {
    top: 0;
    padding-left: 0;
}

.home .blueCircle {
    display: none;
}

.home .security__banner .security__card {
    flex-direction: column;
    width: 33%;
    box-shadow: unset;
    margin: 0;
    height: 100%;
    gap: 10px;
    background: transparent;
    border-radius: 0;
    position: relative;
    border: none;
    align-self: baseline;
}

.home .security__banner .security__card::after {
    content: '';
    background-color: #94EAFF;
    height: 250px;
    width: 1px;
    position: absolute;
    right: 25px;
    top: 15%;
}

.home .security__banner .security__card a{
    position: absolute;
    width: 100%;
    height: 100%;
}   

.home .security__banner .security__card:nth-child(3)::after,
.home .security__banner .security__card:nth-child(6)::after {
    display: none;
}

.home .security__card .security__content {
    max-width: 270px;
}

.security__wrapper {
    background-color: #EDF8FF;
}

.home .security__cards {
    border-radius: 62% 100% 80% 80% / 10% 50% 50% 50%;
    background: rgba(255, 255, 255, 0.8);
    padding-bottom: 200px;
    z-index: 3;
}

.home .hero__banner_right {
    margin-bottom: auto;
    align-items: flex-end;
}

.home .lf-player-container {
    z-index: 5;
    margin-left: auto;
    margin-bottom: auto;
}

.home .capsule__title {
    margin-bottom: 20px;
}

.home .ourClient{
    padding-top: 0;
}

.home .security__banner_left p {
    padding-top: 0;
    padding-bottom: 30px;
}

.home .security__banner .security__content h3 {
    font-weight: 600;
}

.home .topIndustries .topIndustires__left .red-btn{ 
    margin-top: 20px;
}

@media screen and (max-width: 1180px) {

    .home .hero__banner h3.main,
    .home .hero__banner h3.main span,
    .home .hero__banner h3 span.redBold,
    .home .hero__banner h3.main {
        font-size: 35px;
        line-height: 45px;
        padding: 0;
    }

    .home .hero__banner_wrapper {
        padding: 50px 0;
        padding-top: 80px;
    }

    .home .security__banner .security__card::after {
        right: 0;
    }

    .home .hero__banner {
        margin: 0;
        padding-bottom: 0;
        flex-direction: column;
        gap: 140px;
    }

    .home .hero__banner_right,
    .home .hero__banner_left {
        width: 100%;
        align-items: center;
        max-width: unset;
    }

    .home .hero__banner .banner__circle1,
    .home .hero__banner .banner__circle2,
    .home .hero__banner .banner__circle3 {
        left: auto;
    }


    .home .hero__banner .banner__circle2 {
        border-radius: 50%;
        position: absolute;
        width: 700px;
        height: 700px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    
    }

    .home .hero__banner .banner__circle1 {
        border-radius: 50%;
        position: absolute;
        width: 600px;
        height: 600px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    
    }

    .home .hero__banner .banner__circle3 {
        border-radius: 50%;
        position: absolute;
        width: 500px;
        height: 500px;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
    }

    .home .hero__banner_img {
        width: 400px;
        height: 400px;
    }

    .home .hero__banner .banner__circle3::before {
        left: -140px;
    }
    
    .home .hero__banner .banner__circle3::after {
       top: 35px;
    }

    .home .hero__banner .banner__circle2::after{
        left: 574px;
        bottom: 65px;
    }

    .home .hero__banner_left {
        padding-top: 60px;
        padding-left: 20px;
    }

    .home .simple__banner p {
        margin-top: 10px;
    }

    .home .hero__banner_left .red-btn{
        align-self: start;
    }

}

@media screen and (max-width: 767px) {

    .home .hero__banner_right,
    .home .hero__banner_left {
        width: 100%;
        gap: 10px;
    }

    .home .ourClient {
        padding-top: 50px;
    }

    .home .hero__banner {
        margin: 0;
        padding-bottom: 0;
        padding-top: 0;
        gap: 80px;
        flex-direction: column;
    }

    .home .redTitle {
        white-space: unset;
    }

    .home .hero__banner h3.main,
    .home .hero__banner h3.main span,
    .home .hero__banner h3 span.redBold,
    .home .hero__banner h3.main {
        font-size: 28px;
        line-height: 35px;
        padding-top: 0;
    }

    .home .security__banner .security__card {
        width: 90%;
        padding: 0 20px;
    }

    .home .security__banner .security__card::after {
        left: 0;
        top: 0;
        height: 100%;
    }

    .home .security__banner_right {
        justify-content: center;
        align-items: flex-start;
    }

    .home .security__banner .security__card:nth-child(3)::after,
    .home .security__banner .security__card:nth-child(6)::after {
        display: flex;
    }

    .home .hero__banner .banner__circle2 {
        width: 500px;
        height: 500px;
    }

    .home .hero__banner .banner__circle1 {
        width: 400px;
        height: 400px;
    
    }

    .home .hero__banner .banner__circle3 {
        width: 350px;
        height: 350px;
    }

    .home .hero__banner_img {
        width: 250px;
        height: 250px;
    }

    .home .hero__banner_img img {
        width: 200px;
    }

    .home .hero__banner .banner__circle3::before {
        left: 0px;
        width: 50px;
        height: 50px;
    }
    
    .home .hero__banner .banner__circle3::after {
       top: 30px;
       left: 30px;
    }

    .home .hero__banner .banner__circle2::after{
        left: 70%;
        bottom: 60px;
        width: 50px;
        height: 50px;
    }

    .home .security__wrapper {
        padding-top: 80px;
    }


}