.migrate {
    position: relative;
    background-color: #FFF;
}

.migrate::before {
    content: '';
    background: url(.././../images/brick-white.svg) no-repeat;
    position: absolute;
    width: 100%;
    display: flex;
    left: 0;
    top: -3px;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 1180px;
}

.migrate .container {
    flex-direction: column;
    margin-top: 0;
    padding-top: 100px;
}

.migrate .migrate__cards {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    margin: 70px 0;
    position: relative;
    left: 20px;
}

.migrate__cards::before {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    left: 10%;
    top: 10%;
}

.migrate__cards::after {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    right: 0%;
    top: 50%;
}

@-moz-document url-prefix() {

    .migrate__cards::before,
    .migrate__cards::after {
        background: #9ad8ecc4 ;
    }
}

.migrate .migrate__card {
    padding: 20px;
    display: flex;
    gap: 10px;
    width: 48.7%;
    z-index: 2;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
}

.migrate h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
}

.migrate .migrate__card svg {
    min-width: 20px;
    margin-top: 1px;
    color: #BB2525;
}

.migrate .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.migrate .firstImg {
    position: absolute;
    left: 5%;
    bottom: 10%;
}

.migrate h4 {
    margin-bottom: 5px;
}

.migrate .secondImg {
    position: absolute;
    right: 0;
    top: 30%;
}

.migrate .title-text{
    max-width: 50%;
}

.migrate .title-text p{
    padding-left: 20px;
}

.migrate .dataChallenge .data__container_left::before {
    height: 94%;
}

@media screen and (max-width:1180px) {

    .migrate {
        padding: 20px;
    }

    .migrate .migrate__cards {
        justify-content: center;
        left: 0;
    }

    .migrate .migrate__card {
        width: 47.7%;
    }

    .migrate .dataChallenge .data__container_left::before {
        height: 95%;
    }

}

@media screen and (max-width:767px) {

    .migrate .title-text {
        max-width: 100%;
    }

    .migrate .title-container {
        flex-direction: column;
    }

    .migrate h3 {
        max-width: 100%;
    }

    .migrate h3.main, .migrate h3.main .redTitle {
        font-size: 28px;
        line-height: 32px;
    }

    .migrate .migrate__card {
        width: 100%;
        margin: 0 auto;
    }

    .migrate {
        padding: 0 20px;
    }

    .migrate .migrate__cards {
        margin: 20px 0 50px 0;
        gap: 20px;
    }

    .migrate .migrate__card svg {
        width: 50px;
        margin-top: 2px;
    }

    .migrate .title-container img {
        width: 100%;
        margin: 50px 0;
        z-index: 4;
    }

    .migrate .firstImg {
        left: -100px;
    }

    

    .migrate .title-container img {
        margin-bottom: 0;
    }
}