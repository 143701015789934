/* .heroTabs{
    background: #F6FCFF;
    width: 100%;
    max-width: unset;
} */

.heroTabs__container {
    background: #EDF8FF;
    padding: 50px 0;
    position: relative;
}

.heroTabs__container::before {
    content: '';
    background: url(/src/images/top-header-bg.svg) no-repeat;
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    max-width: 1180px;
}

.service__tabs {
    position: relative;
}

.service__tabs.heroTabs {
    margin: 50px auto;
}

.service__tabs h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    /* 133.333% */
    max-width: 58%;
    margin: 0 20px;
}

.heroTab__desp {
    width: 70%;
    text-align: center;
}

.service__tabs .service__container {
    border-radius: 32px;
    border: 2.5px solid #FFF;
    background: linear-gradient(106deg, #FFF 58.25%, rgba(255, 255, 255, 1) 151.73%);
    box-shadow: 0px 20px 57px -32px rgba(0, 59, 102, 0.08);
    padding: 42px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 20px;
    z-index: 4;
    margin: 20px;
    position: relative;
}

.service__tabs::before {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    left: -10%;
    top: 36%;
}

.heroTabs__container .bubble {
    position: absolute;
    left: 0%;
    top: 50%;
}

.service__tabs::after {
    content: '';
    position: absolute;
    border-radius: 297.181px;
    background: rgba(0, 194, 255, 0.99);
    filter: blur(200px);
    width: 297.181px;
    height: 297.181px;
    right: 0%;
    top: 50%;
}

@-moz-document url-prefix() {
    .service__tabs::before {
        background: #9ad8ecc4;
    }

    .service__tabs::after {
        background: #9ad8ecc4;
    }

}

.service__tab {
    width: 31%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    align-items: center;
    text-align: center;
    gap: 20px;
    border: 1px solid transparent;
    transition: .5s background ease-in-out;
}

.service__tab:hover {
    border-radius: 32px;
    border: 1px solid #BB2525;
    background: #FFF9F9;
}

.service__tab h4 {
    color: #000;
    text-align: center;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 32px;
    text-transform: capitalize;
    /* 145.455% */
}

.service__tab p {
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}

.service__tab .service-img {
    max-width: 69px;
    border-radius: 50%;
    width: 100%;
    height: 69px;
    padding: 15px;
    border: 1px solid #BB2525;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
}

.service__tab .service-img img{
    height: 100%;
    width: 100%;
}

.service__tab ul{
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 10px;
    padding-left: 20px;
}

.service__tab li{
    list-style: disc;
    text-transform: capitalize;
}

.service__tab.left-align  h4 {
    text-align: left;
}

.service__tab.left-align {
    align-items: flex-start;
}

@media screen and (max-width:1180px) {

    .heroTabs__container {
        padding-top: 1px;
    }

    .service__tab {
        width: 47%;
    }

    .service__tabs::after {
        right: 0%;
        top: 50%;
    }

    .service__tab h4 {
        font-size: 20px;
        line-height: 32px;
        /* 145.455% */
    }

    .service__tab p {
        font-size: 12px;
        line-height: 24px;
    }

    .service__tabs h3 {
        max-width: unset;
        padding-bottom: 0;
    }

    .service__tabs.heroTabs {
        margin-bottom: 0;
    }

}

@media screen and (max-width:767px) {

    .service__tabs h3.main, .service__tabs h3.main .redTitle {
        font-size: 28px;
        line-height: 32px;
        text-align: left;
    }


    .service__tab {
        width: 100%;
    }

    .service__tabs .service__container {
        margin: 0;
        padding: 0px;
        gap: 0;
    }

    .service__tabs {
        padding: 20px;
        gap: 40px;
    }


    .heroTab__desp {
        width: 100%;
    }

    .service__tabs.heroTabs {
        margin-bottom: 0;
    }

    .heroTabs__container::before {
        content: '';
        position: absolute;
        border-radius: 297.181px;
        background: rgba(0, 194, 255, 0.99);
        filter: blur(200px);
        width: 297.181px;
        height: 297.181px;
        left: -10%;
        top: 86%;
    }

    .service__tabs h3 {
        margin: 0;
        padding-bottom: 0;
    }

    .service__tabs .heroTab__desp{
        text-align: left;
        padding-left: 20px;
    }

    @-moz-document url-prefix() {
    
        .heroTabs__container::before {
            background: #9ad8ecc4;
        }
    }

}