.icons__gradient {
    width: 675px;
    height: 607.58px;
    position: absolute;
    opacity: 0.5;
    filter: blur(200px);
    z-index: -2;
}

.icons__gradient .polygon {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 325px;
    height: 325px;
    background: #0066FF33;
    transform: rotate(147.47deg);
}

.icons__gradient .star {
    position: absolute;
    left: 0;
    top: 50px;
    width: 325px;
    height: 325px;
    background: #B1DEFF73;
    transform: rotate(147.47deg);
}

.icons__gradient .ellipse {
    position: absolute;
    right: 0;
    top: 50px;
    width: 325px;
    height: 325px;
    background: #00C2FFFC;
    transform: rotate(147.47deg);
}

.icons__gradient .star {
    position: absolute;
    right: 0;
    top: 50px;
    width: 325px;
    height: 325px;
    background: #FF0000;
    transform: rotate(147.47deg);
}

@-moz-document url-prefix() {
    .icons__gradient .ellipse {
        background: #9ad8ecc4 ; /* Adjust the color as needed */
    }

    .icons__gradient .star {
        background: #E4B2B2;;
    }

    .gradient__box{
        background: #9ad8ecc4  !important;
    }
}
