.contactUs__wrapper{
    background-color: #fff;
}
.contactUs{
    display: flex;
    max-width: 1180px;
    margin: 0 auto;
    padding-top: 100px;
    position: relative;
}

.contactUs__left,
.contactUs__right{
    width: 50%;
    position: relative;
}

.contactUs__left h3{
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
    max-width: 500px;
    text-align: left;
    margin-bottom: 50px;
}

.contactUs__left span{
    font-weight: 700;
}

.contactUs__cards{
    display: flex;
    position: relative;
    flex-direction: column;
    gap: 40px;
    padding-bottom: 100px;
    border-left: 1px dashed #BB2525;
    margin-left: 50px;
}

.contactUs__cards::after{
    position: absolute;
    height: 100%;
    background: #BB2525;
    width: 1px;
}

.contactUs__cards .contactUs__card{
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 0 80px;
    padding-left: 60px;
}

.contactUs__cards .contactUs__card h4{
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
    text-align: left;
    margin-top: 20px;
    margin-bottom: 10px;
}

.contactUs__cards .contactUs__card h4 span{
    color: #BB2525;
}

.contactUs__cards .contactUs__card p{
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
}

.contactUs__cards .contactUs__card .step{
    position: absolute;
    left: -35px;
    height: 64px;
    width: 64px;
    background: linear-gradient(84.98deg, #37D8EE -35.56%, #FCEAFF 120.13%);
    box-shadow: -20px 10px 18px 4px #00000008;
    color: #fff;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 14px;
}

  @media screen and (max-width: 1180px) {

  .contactUs {
    padding: 0 20px;
    padding-top: 50px;
    margin-top: 14px;
  }

  .contactUs__cards {
    gap: 40px;
  }

  .contactUs__cards .contactUs__card {
    padding: 0 40px;
  }

  .contactus-page .ourClient_wrapper {
    padding-top: 50px;
}

}

@media screen and (max-width: 767px) {

    .contactUs{
        flex-direction: column-reverse;
        gap: 50px;
        padding-top: 100px;
    }

    .contactUs__left, .contactUs__right {
        width: 100%;
    }

    .contactUs__cards {
        margin-left: 30px;
        
    }

    .contactUs__cards .contactUs__card {
        padding-right: 0;
    }

}