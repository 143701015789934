.privacy .dataChallenge h3.main,
.privacy .data__container_right{
    display: none;
}

.privacy .data__container{
    padding-top: 100px;
    position: relative;
}

.privacy .data__container::after{
    content: '';
    background: url(../../images/top-header-bg.svg);
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
}

.privacy .data__container_left{
    width: 100%;
}

.privacy .data_desp{
    padding: 20px 30px;
    display: flex;
    gap: 10px;
    z-index: 2;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    justify-content: space-between;
    cursor: pointer;
    height: max-content;
    margin-top: -70px;
    margin-left: 20px;
    position: relative;
}

.privacy .data_desp::after {
    content: '';
    position: absolute;
    background-color: #BB2525;
    width: 20px;
    height: 1px;
    top: 50%;
    left: -24px;
}

.privacy .dataChallenge .data__container_left::before {
    height: 94%;
    top: 0px;
}

.privacy .simple__banner .simple__left h3 {
font-weight: 700;
line-height: 60px;
letter-spacing: 0em;
text-align: left;
max-width: 500px;
}

.privacy .data_desp::before {
    content: '';
    position: absolute;
    background-color: #BB2525;
    width: 1px;
    height: 50%;
    top: 50%;
    left: -24px;
}

@media screen and (max-width: 1180px) {

.privacy .data_desp {
    margin-top: -10%;
}

.privacy .simple__banner .simple__left h3 {
    line-height: 40px;
}

}

@media screen and (max-width: 767px) {

    .privacy .dataChallenge .data__container_left:before {
        height: 90%;
        top: 50px;
    }

    .privacy .data_desp {
        margin-top: -100px;
    }

    

}