.topIndustires__wrapper {
    background: #EDF8FF;
    padding: 1px;
    position: relative;
}

.topIndustries {
    background-color: #fff;
    max-width: 1201px;
    margin: 100px auto;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    display: flex;
    margin-top: 0;
    margin-top: -250px;
    z-index: 6;
    position: relative;
}

.topIndustries .topIndustires__left {
    width: 50%;
    display: flex;
    gap: 30px;
    flex-direction: column;
    padding: 100px;
}

.topIndustires__left h3 {
    font-size: 36px;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.topIndustries .topIndustires__right {
    display: flex;
    width: 50%;
    justify-content: space-evenly;
}

.topIndustires__left h3 span:nth-child(2) {
    font-weight: 400;
}

.topIndustries .topIndustries__img_right {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.topIndustries .topIndustries__img_right .capsule {
    width: 145px;
    border-radius: 100px;
    border: 1px solid #000;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
    text-align: center;
    padding: 20px;
}

.topIndustries .topIndustries__img_right .img {
    width: 103px;
    height: 103px;
    background: linear-gradient(323deg, #17213D 10.25%, #730B0B 95.04%);
    border-radius: 50%;
    padding: 20px;
    filter: drop-shadow(0px 14px 16px rgba(0, 0, 0, 0.25));
    object-fit: none;
}

.topIndustries__img_right .capsule__content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.topIndustries__img_right .capsule__content p {
    line-height: 17px;
}

.topIndustries__img1 .capsule:nth-child(1) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}

.topIndustries__img1 .capsule:nth-child(2) {
    background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 30px 10px;
}

.topIndustries__img1 .capsule:nth-child(3) {
    background: #16213E;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding: 0;
    height: 70px;
    width: 100%;
    height: 100%;
}

.topIndustries__img2 .capsule:nth-child(1) img,
.topIndustries__img2 .capsule:nth-child(1) .capsule__content,
.topIndustries__img1 .capsule:nth-child(3) img,
.topIndustries__img1 .capsule:nth-child(3) .capsule__content {
    display: none;
}

.topIndustries__img2 .capsule:nth-child(3) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    padding-bottom: 30px;
    border-bottom: none;
}

.topIndustries__img2 .capsule:nth-child(2) {
    background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 30px 10px;
    flex-direction: column-reverse;
}

.topIndustries__img2 .capsule:nth-child(1) {
    background: #16213E;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 0;
    height: 100px;
    width: 100%;
}

.topIndustries__img3 .capsule:nth-child(1) {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;
}

.topIndustries__img3 .capsule:nth-child(2) {
    background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    padding: 30px 10px;
    margin-bottom: 20px;
}

.topIndustires__wrapper .gradient__box{
    z-index: 1;
}

@media screen and (max-width: 1180px) {

    .topIndustries .topIndustires__left {
        width: 40%;
        padding: 50px;
    }

    .topIndustries .topIndustires__right {
        width: 60%;
    }

    .topIndustires__left .red-btn {
        font-size: 13px;
        padding: 15px;
    }

    .topIndustries {
        margin-top: -200px;
        margin-bottom: 70px;
    }
}

@media screen and (max-width: 767px) {

    .topIndustries {
        flex-direction: column;
        margin: 0;
        padding: 0 20px;
        margin-top: -40px;
    }

    .topIndustries .topIndustires__left {
        width: 100%;
        padding: 50px 0;
        gap: 20px;
    }

    .topIndustries .topIndustires__right {
        width: 100%;
        justify-content: space-between;
    }

    .topIndustries .topIndustries__img3 {
        display: none;
    }

    .testimonial .testimonial__left{
        padding-top: 100px;
    }

    .topIndustries__img1 .capsule:nth-child(1),
    .topIndustries__img2 .capsule:nth-child(3) {
        background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
    }

    .topIndustries__img1 .capsule:nth-child(3) {
        border-radius: 100px;
        padding-bottom: 30px;
        background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
        border-bottom: none;
        flex-direction: column-reverse;
        width: 145px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        padding: 0 20px;
        gap: 15px;
    padding-top: 15px;
    }

    .topIndustries__img2 .capsule:nth-child(1) {
        border-radius: 100px;
        padding-bottom: 30px;
        height: unset;
        background: linear-gradient(180deg, #D2EAFF 0%, #B4ECFF 100%), linear-gradient(0deg, #FFFFFF, #FFFFFF);
        width: 145px;
        flex-direction: column-reverse;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-top: none;
        padding: 20px;
    }

    .topIndustries__img2 .capsule:nth-child(1) img,
    .topIndustries__img2 .capsule:nth-child(1) .capsule__content,
    .topIndustries__img1 .capsule:nth-child(3) img,
    .topIndustries__img1 .capsule:nth-child(3) .capsule__content{
        display: flex;
    }
    
    .home .security__cards {
        border-radius: 60% 60% 60% 60% / 10% 10% 10% 10%;
        padding-bottom: 0px;
        padding-top: 50px;
    }

    .home .topIndustries .topIndustries__img_right {
        justify-content: space-between;
        gap: 30px;
    }

}