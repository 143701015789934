.contactUs__right form {
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    display: flex;
    flex-direction: column;
    gap: 30px;
    position: relative;
    padding: 30px;
    z-index: 2;
}

.contactUs__right .bubble-icon {
    content: '';
    position: absolute;
    background: linear-gradient(84.98deg, #43E5FB -35.56%, #FCEAFF 120.13%);
    width: 111px;
    height: 111px;
    top: -50px;
    right: 50px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.contactUs__right .bubble-icon::before {
    content: '';
    width: 90px;
    height: 90px;
    background: linear-gradient(109.01deg, #43E5FB -60.42%, #FCEAFF 94.99%);
    position: absolute;
    border-radius: 50%;
}

.contactUs__right .bubble-icon img {
    z-index: 1;
}

.error {
    color: red;
    font-size: 12px;
    margin-top: 4px;
    /* Adjust margin as needed */
}

.contactUs__right form h4 {
    font-size: 26px;
    font-weight: 700;
    line-height: 20px;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 10px;
    position: relative;
}

.contactUs__right form h4::after {
    content: '';
    position: absolute;
    background-color: #BB2525;
    height: 3px;
    top: -40px;
    left: 0px;
    width: 40px;
}

.contactUs__right form h4::before {
    content: '';
    position: absolute;
    background-color: #BB2525;
    width: 100px;
    height: 3px;
    top: -15px;
    left: 0;
}

.contactUs__right .input__wrapper {
    width: 100%;
}

.contactUs__right form textarea,
.contactUs__right form input {
    border: 2px solid #C7E7F2;
    padding: 20px;
    border-radius: 20px;
    width: 100%;
}

.contactUs__right .inputs {
    display: flex;
    justify-content: space-between;
    gap: 20px;
}

.contactUs__right form button {
    margin: 0 auto;
}

.contactUs__right form textarea {
    height: 166px;
    resize: none;
}

.contactUs__right textarea::placeholder,
.contactUs__right input::placeholder,
.contactUs__right textarea,
.contactUs__right input {
    border: none;
    outline: none;
    color: #B2D2DD;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    opacity: 1;
    /* Firefox */
}

.contactUs__right .icons__gradient {
    z-index: 1;
}

.contactus-btn.btn {
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    padding: 15px 0px;
    position: relative;
    width: 210px;
    pointer-events: unset;
    border: none;
    margin: 0 auto;
}

.contactus-btn.btn.success {
    background: #16213E;
    border: none;
}

.contactus-btn.btn .text  .contactus-btn.btn .success{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    transition: all 0.3s ease-in-out;
}

.contactus-btn.btn span.success-text {
    visibility: hidden;
    display: none;
    opacity: 0;
}


.contactus-btn.btn:active {
    transform: scale(1) translateY(0) translateZ(0);
}

.contactus-btn.btn:active:before {
    box-shadow: none;
}

.contactus-btn.btn.clicked {
    transform: scale(1) translateY(0) translateZ(0);
}

.contactus-btn.btn.clicked:before {
    box-shadow: none;
}

.contactus-btn.btn.success {
    color: #FFF;
    pointer-events: none;
}

.contactus-btn.btn.success:hover {
    cursor: default;
}

.contactus-btn.btn.success span.text {
    opacity: 0;
    visibility: hidden;
    display: none;
}

.contactus-btn.btn.success span.success-text {
    transform: translate(-50%, -50%);
    opacity: 1;
    visibility: visible;
    display: none;
}

.contactus-btn.btn.success:before {
    right: 10px;
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background: #4db948;
    border-color: #3e9539;
}


@media screen and (max-width: 1180px) {

    .contactUs__right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .contactUs__right form h4 {
        line-height: 40px;
    }

    .contactUs__right form textarea,
    .contactUs__right form input {
        padding: 10px 15px;
        border-radius: 10px;
    }

    .contactUs .icons__gradient {
        width: 500px;
        height: 500px;
        bottom: 10%;
    }

}

@media screen and (max-width: 767px) {

    .contactUs__right form {
        padding: 20px;
        gap: 20px;
    }

    .contactUs__right {
        width: 100%;
    }

}