

.cloud-migrate .all-clouds {
    background-image: url(../../../images/migrate/HeaderMigrate.svg);
    background-position: 15px 100px;
}

.cloud-migrate .title__banner {
    max-width: 1232px;
    padding: 40px 80px;
    padding-top: 170px;
}

.cloud-migrate  .all-stats .stats__box:nth-of-type(1) {
    width: 231px;
    height: 120px;
}

.cloud-migrate  .all-stats .stats__box:nth-of-type(2) {
    width: 210px;
    height: 120px;
}

.cloud-migrate  .all-stats .stats__box:nth-of-type(3) {
    width: 385px;
    height: 120px;
}

.cloud-migrate  .all-stats .stats__box:nth-of-type(4) {
    width: 185px;
    height: 120px;
}

.cloud-migrate .dataChallenge .data__container_left::before {
    height:  88%;
 }

 .cloud-migrate .data__card ul {
    display: flex;
    gap: 10px;
    flex-direction: column;
 }

 .cloud-migrate .data__container_left .data__card h4 {
    margin-bottom: 10px;
 }

 .cloud-migrate .all-clouds .migrateHeaderImg1 ,
    .cloud-migrate .all-clouds .migrateHeaderImg2 ,
        .cloud-migrate .all-clouds .migrateHeaderImg3 {

display: none;
position: absolute;
        }


@media screen and (max-width:1180px) {


    .cloud-migrate  .all-stats .stats__box:nth-of-type(1) ,
    .cloud-migrate  .all-stats .stats__box:nth-of-type(2) ,
    .cloud-migrate  .all-stats .stats__box:nth-of-type(3) ,
    .cloud-migrate  .all-stats .stats__box:nth-of-type(4) {
        width: 48%;
        height: auto;
    }

    .cloud-migrate  .all-stats .stats__box:nth-of-type(4) {
        margin-bottom: auto;
    }

    .cloud-migrate .dataChallenge .data__container_left::before {
       height:  84%;
    }

    .cloud-migrate{
        overflow-x: hidden;
    }

    .cloud-migrate .all-clouds {
        background-image: url(../../../images/migrate/HeaderMigrate.svg);
        background-position: 4px 90px;
    }
    

}


@media screen and (max-width:767px){
    
    .cloud-migrate .title__banner {
        padding: 10px;
        padding-top: 90px;
    }

    .cloud-migrate .migrate::before {
        display: none;
    }

    .cloud-migrate .migrate .container {
        padding-top: 50px ;
        padding-bottom: 0;
    }

    .cloud-migrate .dataChallenge .data__container_left::before {
        height: 87%;
    }

    .cloud-migrate .all-clouds {
        background: unset;
        position: relative;
    }

    .cloud-migrate .all-clouds .migrateHeaderImg1 {
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: contain;
        right: 15px;
        bottom: 20%;    
        background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
        padding: 10px;
        border-radius: 50%;
        display: block;
    }

    .cloud-migrate .all-clouds .migrateHeaderImg2 {
        position: absolute;
        width: 50px;
        height: 50px;
        background-size: contain;
        left: 10px;
        bottom: 20%;
        background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
        padding: 10px;
        border-radius: 50%;
        display: block;
    }

    .cloud-migrate .all-clouds .migrateHeaderImg3 {
        position: absolute;
        width: 60px;
        height: 60px;
        background-size: contain;
        left: 70%;
        padding: 10px;
        border-radius: 50%;
        top: 60px;    
        display: block;
    }

    .cloud-migrate .all-clouds .migrateHeaderImg1 img ,
    .cloud-migrate .all-clouds .migrateHeaderImg2 img,
    .cloud-migrate .all-clouds .migrateHeaderImg3 img{
        height: 100%;
    }

    .cloud-migrate .title__banner h2 {
        max-width: 300px;
    }
}


@media screen and (max-width:395px){

    .cloud-migrate .dataChallenge .data__container_left::before {
        height: 85.5%;
    }

}