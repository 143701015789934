.ourClient_wrapper {
    background: #EDF8FF;
    position: relative;

}

.ourClient {
    max-width: 1180px;
    margin: 0px auto;
    padding: 100px 0;
}

.ourClient .ourClient__title_container {
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.ourClient h3 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
    letter-spacing: 0em;
    text-align: center;
}

.ourClient .ourClient__title {
    max-width: 80%;
}

.ourClient h3 span:nth-child(1) {
    font-weight: 600;
}

.ourClient p {
    text-align: center;
    max-width: 500px;
    margin: 0 auto;
    margin-top: 20px;
}

.ourClient .smallBubble {
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 35px;
    height: 35px;
    display: flex;
    border-radius: 50%;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
}

.ourClient__Container {
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
    gap: 56px;
    align-items: center;
    margin-top: 50px;
}

.ourClient__Container .ourClient__icon {
    border: 3px solid #FFFFFF;
    width: 174px;
    height: 174px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-shadow: -10px 4px 23px 4px #0000000F;
    z-index: 1;
    background: linear-gradient(26.98deg, #EAFCFF 4.58%, rgba(255, 255, 255, 0.48) 94.34%);
}

.ourClient__Container .ourClient__icon img {
    width: 100px;
    height: 100px;
}

/* faq */

@media screen and (max-width: 1180px) {

    .ourClient__Container {
        justify-content: space-evenly;
        padding: 0 16px;
    }

    .ourClient__Container .ourClient__icon {
        width: 128px;
        height: 128px;
    }

    .ourClient__Container .ourClient__icon img {
        width: 80px;
        height: 80px;
    }

    .ourClient .smallBubble {
        display: none;
    }

}

@media only screen and (max-width: 1200px) and (min-width: 1180px) {

    .ourClient__Container {
        column-gap: 50px;

    }
}

@media only screen and (max-width: 1180px) and (min-width: 900px) {

    .ourClient__Container {
        column-gap: 128px;

    }
}

@media only screen and (max-width: 928px) and (min-width: 800px) {

    .ourClient__Container {
        column-gap: 100px;

    }
}




@media only screen and (max-width: 900px) {

    .ourClient__Container {
        column-gap: 70px;

    }

    .ourClient {
        padding-top: 0;
    }

    .OurSpecialities::before {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    .ourClient {
        padding: 50px 0;
    }
}