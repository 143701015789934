/* .cumuli .header__logo{
    max-width: 80px;
    max-height: 80px;
    min-height: 80px;
} */

.cumuli .title__banner p {
    text-align: center;
}

.cumuli .migrate .migrate__card img {
    width: 50px;
    object-fit: contain;
    margin-bottom: auto;
}

.cumuli .title-container img {
    display: none;
}

.cumuli .title-container {
    justify-content: center;
}

.cumuli .migrate::before {
    display: none;
}

.cumuli .security__banner .security__card {
    width: 30%;
    gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: auto;
    padding: 20px;
}

.cumuli .security__card .security__content {
    gap: 0;
    padding-bottom: 0;
}

.cumuli .security__card h3 {
    font-size: 16px;
}

.cumuli .security__banner_right {
    top: 20px;
}

.cumuli .security__banner_left .contactBtn {
    margin-bottom: 0;
    margin-left: auto;
}

.cumuli .security__banner_left p {
    text-align: end;
}

.cumuli .security__banner_left .header__left p {
     text-align: start;
}

.cumuli .migrate .title-text {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:1190px) {
    .cumuli .security__banner .security__card {
        width: 48%;
        gap: 20px;
    }

    .cumuli .security__banner .security__card{
        margin-top: 0;
    }

    .cumuli .security__card .security__content {
        padding-bottom: 0;
    }

    .cumuli .security__cards{
        padding-bottom: 50px;
    }
}

@media screen and (max-width:767px) {
    .cumuli .security__banner .security__card {
        padding: 20px;
        width: 100%;
        gap: 20px;
        flex-direction: row;
    }

    .cumuli .security__banner_left .contactBtn {
        margin: 0;
        margin-bottom: 15px;
    }

    .cumuli .security__banner_left p {
        text-align: left;
    }
}


@media screen and (max-width: 767px) {
    .cumuli .title__banner {
        padding: 20px;
        padding-top: 140px;
    }

    /* .cumuli .header__logo{
        max-width: 60px;
        max-height: 80px;
        min-height: 60px;
    } */

}