/* TextReveal.css */
.reveal-text {
    opacity: 0;
    transform: translateY(80px);
    transition: opacity 2s ease-out, transform 0.8s ease-out;
  }
  
  .reveal-text.visible {
    opacity: 1;
    transform: translateY(0);
  }
  