
.devops .all-clouds {
    background-image: url(../../../images/devOps/HeaderDevops.svg);
    background-position: 5px 178px;
}

.devops .all-stats .stats__box:nth-of-type(1) {
    width: 201px;
    height: 120px;
}

.devops .all-stats .stats__box:nth-of-type(2) {
    width: 250px;
    height: 120px;
}

.devops .all-stats .stats__box:nth-of-type(3) {
    width: 325px;
    height: 120px;
}

.devops .hori__banner .hori__card {
    height: auto;
}

.devops .redBold {
    color: #BB2525;
}

.devops .hero__banner h3 {
    padding: 0;
}

.devops .hero__banner strong{
    font-weight: 500;
    font-size: 25px;
    line-height: 40px;
}

.devops .migrate::before{
    display: none;
}

.devops .red-btn{
    margin-top: 30px;
}


@media screen and (max-width:1180px) {

    .devops{
        overflow-x: hidden;
    }

.devops .all-stats .stats__box:nth-of-type(1) ,
.devops .all-stats .stats__box:nth-of-type(2) {
    width: 140px;
    height: auto;
}

.devops .all-stats .stats__box:nth-of-type(3) {
    width: 325px;
    height: auto;
}

.devops .all-stats{
    margin: 0;
}

.devops .simple__banner .simple__left {
    gap: 10px;
}   

}

@media screen and (max-width:767px) {
    .devops .all-clouds {
        background: unset;
        position: relative;
    }

    .devops .all-clouds::before {
        position: absolute;
        content: '';
        background-image: url('/src/images/devOps/devHeader1.svg');
        width: 100px;
        height: 100px;
        background-size: contain;
        right: -26px;
        bottom: 10%;
    }

    .devops .all-clouds::after {
        position: absolute;
        content: '';
        background-image: url('/src/images/devOps/devHeader2.svg');
        width: 40px;
        height: 40px;
        background-size: contain;
        left: 10px;
        bottom: 30%;
    }

    .devops .migrate .container {
        padding-top: 50px ;
        padding-bottom: 0;
    }

    .devops .simple__banner .simple__left h3 {
        margin-bottom: 0;
    }
}