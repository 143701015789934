.dataChallenge {
    padding: 1px;
    background: #EDF8FF;
}

.dataChallenge .container {
    flex-direction: column;
    margin-top: 0;
    padding-bottom: 80px;
}

.dataChallenge h3.main {
    font-size: 36px;
    font-weight: 600;
    line-height: 48px;
    border-left: 1px solid #BB2525;
    padding-bottom: 0;
}

.dataChallenge h3.main::after {
    height: 1px;

}

.dataChallenge h3.main::before {
    display: none;
}

.data_desp {
    padding-left: 20px;
    border-left: 1px solid #BB2525;
}

.dataChallenge .data__container {
    display: flex;
}

.dataChallenge .data__container_left {
    width: 60%;
}

.dataChallenge .data__container_right {
    width: 40%;
}

.dataChallenge .data__container_left {
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
}

.dataChallenge .data__container_left::before {
    content: '';
    width: 1px;
    height: 90%;
    background-color: #BB2525;
    left: 0;
    top: 0;
    display: flex;
    position: absolute;
}

.dataChallenge .data__container_left .data__card {
    display: flex;
    gap: 20px;
    padding-left: 20px;
    position: relative;
}

.dataChallenge .data__container_left .data__card::before {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: url(../../images/challengArrow.svg) center no-repeat;
    transform: rotate(360deg);
    background-size: contain;
    left: -7px;
    top: 17px;

}

.data__container_left .data__card .num {
    color: #BB2525;
    font-size: 30px;
    font-weight: 400;
    line-height: 48px;
}

.data__container_left .data__card h4 {
    font-size: 20px;
    font-weight: 600;
    line-height: 34px;
}

.data__container_left .data__card p {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
}

.data__card ul{
    padding-left: 20px;
}

.data__card li{
    list-style: disc;
}

.data__card .data__card_content{
    padding-top: 5px;
}

.data__card .data__card_subdesp{
    font-weight: 600;
}

.data__img_left {
    width: 160px;
    height: 350px;
    border-radius: 100px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.data__container_right {
    display: flex;
    justify-content: center;
    gap: 30px;
    align-items: center;
    position: sticky;
    height: fit-content;
    top: 120px;
    margin-bottom: 20px;
}

.data__card_container {
    position: sticky;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.data__img_right {
    display: flex;
    flex-direction: column;
    gap: 30px;
}

.data__img_right .capsule {
    width: 145px;
    height: 240px;
    border-radius: 100px;
    border: 1px solid #000;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    gap: 0px;
    flex-direction: column;
    text-align: center;
}

.data__img_right .img {
    width: 103px;
    height: 103px;
    background: linear-gradient(323deg, #17213D 10.25%, #730B0B 95.04%);
    border-radius: 50%;
    padding: 20px;
    filter: drop-shadow(0px 14px 16px rgba(0, 0, 0, 0.25));
}

.dataChallenge .capsule__content {
    margin-top: 20px;
}

.dataChallenge .capsule__content:nth-child(2) {
    margin-top: 0px;
    margin-bottom: 20px;
}

.data__container_right.mob{
    display: none;
}

@media screen and (max-width:767px) {

    .data__container {
        flex-direction: column;
    }

    .dataChallenge .data__container_right {
        margin-top: 30px;
    }

    .dataChallenge .data__container_left,
    .dataChallenge .data__container_right {
        width: 100%;
    }

    .dataChallenge .container {
        padding: 20px;
    }

    .data__img_right .img {
        width: 80px;
        height: 80px;
    }

    .data__img_right .capsule {
        width: 120px;
        height: 220px;
        gap: 0px;
    }

    .dataChallenge h3.main {
        font-size: 28px;
    }

    .data__container_right.mob{
        display: none;
    }

}

@media screen and (max-width:1180px) {


    .dataChallenge .data__container_left,
    .dataChallenge .data__container_right {
        width: 100%;
    }

    .dataChallenge .container {
        padding: 20px;
        margin: 0;
    }

    .dataChallenge {
        padding: 20px;
    }

}

@media screen and (max-width:767px) {
    .dataChallenge h3.main, .dataChallenge h3.main .redTitle {
        font-size: 28px;
        line-height: 32px;
    }

    .data__container {
        flex-direction: column;
    }

    .data__card_container {
        position: relative;
    }

    .data__container_right {
        justify-content: center;
        gap: 40px;
        position: relative;
        top: 0;
    }

    .data__img_right {
        gap: 20px;
    }

    .dataChallenge .container {
        padding: 0px;
        margin: 0;
    }

    .data__container_right{
        empty-cells: none;
        display: none;
    }

    .data__container_right.mob{
        display: flex;
        padding-left: 20px;
    }

    .dataChallenge .data__container_left .data__card:last-child::after {
        content: '';
        background: #BB2525;
        width: 1px;
        height: 20px;
        position: absolute;
        left: 0;
    }

}