.title__banner {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    max-width: 1180px;
    flex-direction: column;
    gap: 30px;
    margin: 0px auto;
    width: fit-content;
    padding: 40px 120px;
    padding-top: 170px;
}

.all-clouds {
    background: url(../../../images/all-clouds.svg) no-repeat;
    background-position: 0%;
    background-size: contain;
}

.title__banner h2 {
    color: #16213E;
    font-size: 50px;
    font-weight: 700;
    line-height: 60px;
    z-index: 2;
    text-align: center;
    /* 120% */
}

.title__banner .redTitle {
    font-size: 50px;
    line-height: 60px;
}

.title__small {
    font-weight: 400;
}

.active__link {
    color: #BB2525 !important;
}

.container {
    display: flex;
    max-width: 1180px;
    margin: 100px auto;
    margin-bottom: 0;
}

.clouds .all-stats .stats__box:nth-of-type(1) {
    width: 192px;
    height: 129px;
}

.clouds .all-stats .stats__box:nth-of-type(2) {
    width: 161px;
    height: 109.618px;
}

.clouds .all-stats .stats__box:nth-of-type(3) {
    width: 188px;
    height: 126px;
}

.clouds .all-stats .stats__box:nth-of-type(4) {
    width: 147px;
    height: 137px;
}

.clouds .all-stats .stats__box:nth-of-type(5) {
    width: 246px;
    height: 94px;
}

@media screen and (max-width:1180px) {


    .clouds .all-stats .stats__box:nth-of-type(1),
    .clouds .all-stats .stats__box:nth-of-type(2),
    .clouds .all-stats .stats__box:nth-of-type(3),
    .clouds .all-stats .stats__box:nth-of-type(4),
    .clouds .all-stats .stats__box:nth-of-type(5) {
        width: 45%;
        height: fit-content;
    }

    .title__banner {
        padding: 130px 68px;
        padding-bottom: 50px;
    }

    .clouds .all-stats .stats__box {
        padding: 10px 25px;
    }

}

@media screen and (max-width:767px) {

    .clouds{
        overflow-x: hidden;
    }

    .title__banner {
        padding: 20px;
        padding-top: 100px;
    }

    .title__banner h2 {
        font-size: 36px;
        line-height: 46px;
        text-align: center;
    }

    .title__banner .redTitle {
        font-size: 36px;
    }

    .clouds .service__tabs.heroTabs{
        margin-top: 0;
    }

    .clouds .hori__banner {
        margin-bottom: 0;
    }


}