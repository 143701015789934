.faq-page{
    overflow: hidden;
}

.faq-page .faq .container {
    margin-top:0 ;
    position: relative;
}

.faq-page .title__banner{
    position: relative;
    width: 100%;
}

.faq-page .faq{
    z-index: 1;
    background-image: unset;
}

.que{
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 70px;
    height: 70px;
    display: flex;
    border-radius: 50%;
    left: 0px;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    bottom: 0;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 40px;
}

.title__banner .que1{
    left: 20%;
    bottom: 2%;
    z-index: 2;
}

.title__banner .que2{
    width: 35px;
    height: 35px;
    left: 35%;
    bottom: 40%;
    font-size: 20px;
}

.title__banner .que3{
    width: 35px;
    height: 35px;
    right: 35%;
    bottom: 40%;
    font-size: 20px;
    left: unset;
}

.title__banner .que4{
    width: 35px;
    height: 35px;
    right: 20%;
    bottom: 10%;
    font-size: 20px;
    left: unset;
}

.title__banner .que5{
    width: 50px;
    height: 50px;
    right: 10%;
    bottom: 40%;
    font-size: 28px;
    left: unset;
}

.faq-page .faq .container::before{
    content: '';
    background: url(../../images/top-header-bg.svg);
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
}

.faq-page .faq{
    position: relative;
}

.faq-page .que6{
    display: flex;
    width: 230px;
    height: 230px;
    transform: rotate(0deg);
    bottom: 30%;
    background: unset;
    border-radius: unset;
    box-shadow: unset;
}

.faq-page .que6 img{
    width: 100%;
}

@media screen and (max-width: 767px){

    
.title__banner .que1{
    left: 10%;
    bottom: 2%;
    z-index: 2;
}

.title__banner .que2{
    left: 15%;
    bottom: 40%;
}

.title__banner .que3{
    width: 35px;
    height: 35px;
    right: 25%;
    bottom: 40%;
    font-size: 20px;
    left: unset;
}

.title__banner .que4{
    width: 35px;
    height: 35px;
    right: 15%;
    bottom: 10%;
    font-size: 20px;
    left: unset;
}

.title__banner .que5{
    width: 50px;
    height: 50px;
    right: 3%;
    bottom: 32%;
    font-size: 28px;
    left: unset;
}

.faq-page .title__banner {
    padding-bottom: 60px;
}

.faq-page .faq__container {
    margin: 40px 0;
}

.faq-page .contactUs__wrapper{
    overflow: hidden;
}

}