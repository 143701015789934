.our-team{
    overflow-x: hidden;
}

.team__objective {
    background: #EDF8FF;
}

.team__objective-container {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
}

.team__objective-container:before {
    content: '';
    background: url(../../images/top-header-bg.svg);
    position: absolute;
    top: -40px;
    width: 100%;
    display: flex;
    left: 0;
    height: 40px;
    background-repeat: no-repeat;
}

.team__objective-container {
    display: flex;
    padding: 100px 0;
    align-items: center;
    border-bottom: 3px solid #fff;
}

.team__objective-left,
.team__objective-right {
    width: 50%;
}

.team__objective-left h4 {
    font-size: 35px;
    font-weight: 700;
    line-height: 45px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
}

.team__objective-left p {
    font-weight: 500;
    max-width: 400px;
}

.team__objective-right {
    display: flex;
    flex-direction: column;
    gap: 60px;
    position: relative;
}

.team__objective-right .icons__gradient {
    width: 75px;
    height: 564.58px;
}

.team__objective .obj__card {
    width: 100%;
    padding: 30px 20px;
    display: flex;
    max-width: 500px;
    gap: 10px;
    justify-content: flex-start;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    cursor: pointer;
    height: max-content;
    position: relative;
    z-index: 2;
    padding-left: 80px;
}

.obj__card .obj__title {
    color: #BB2525;
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
}

.obj__card .obj__icon {
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 100px;
    height: 100px;
    display: flex;
    border-radius: 50%;
    left: 0px;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    top: -50px;
    left: -50px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff;
}

.team__objective .icons__gradient{
    z-index: 1;
}

.team__cards {
    background: #EDF8FF;
    position: relative;
}

.team__cards .bubble{
    position: absolute;
    bottom: 10%;
}

.team__cards_container {
    display: flex;
    padding: 100px 0;
    max-width: 1180px;
    margin: 0 auto;
    align-items: center;
    justify-content: space-around;
}

.teamContent {
    height: 250px;
    width: 250px;
    position: relative;
    padding: 20px 20px;
    display: flex;
    max-width: 500px;
    gap: 10px;
    justify-content: center;
    border-radius: 32px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 93px 58px -32px rgba(0, 59, 102, 0.11);
    padding-bottom: 0;
    transition: .5s all ease-in-out;
}

.teamCard{
    z-index: 2;
}

.teamCard img {
    transition: .5s all ease-in-out;
    transform: scale(1) translateY(0px);
    object-fit: contain;
}

.teamCard:hover .teamContent{
    background: linear-gradient(279.41deg, #EA8484 -97.6%, rgba(253, 217, 217, 0.15) 116.8%);
}

.teamCard h4 {
    font-size: 24px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
}

.teamCard span {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    display: flex;
    justify-content: center;
}

.our-team .simple__banner .container {
    flex-direction: column;
    gap: 30px;
}

.our-team .simple__left .red-text{
    text-align: center;
}

.our-team .simple__right{
    width: 100%;
    display: flex;
    max-width: 500px;
    justify-content: center;
    gap: 20px;
}

.our-team .teamContent::before{
    content: '';
    position: absolute;
    box-shadow: -20px 10px 18px 4px #00000008;
    width: 60px;
    height: 60px;
    display: flex;
    border-radius: 50%;
    left: 0px;
    bottom: 450px;
    background: linear-gradient(84.98deg, #66EDFF -35.56%, #FCEAFF 120.13%);
    top: -50px;
    left: -50px;
    justify-content: center;
    align-items: center;
    font-size: 50px;
    color: #fff;
}

.our-team .teamCard:nth-child(1) .teamContent::before{
    right: -30px;
    left: unset;
    top: -30px;
}

.our-team .teamCard:nth-child(2) .teamContent::before{
    display: none;
}

.our-team .teamCard:nth-child(3) .teamContent::before{
    left: -40px;
    bottom: -20px;
    top: unset;
}

.our-team .gradient__box{
    z-index: 1;
}

@media screen and (max-width: 1180px) {

.team__cards_container{
    flex-wrap: wrap;
    row-gap: 70px;
    justify-content: space-evenly;
}

.our-team .simple__banner .simple__left h3 {
    text-align: center;
    line-height: 40px;
}

.team__objective-right {
    width: 45%;
}

.obj__card .obj__title {
    font-size: 22px;
}

.team__objective-container {
    padding: 50px 20px;
    justify-content: space-between;
}

.obj__card .obj__icon {
    height: 60px;
    width: 60px;
    font-size: 30px;
    left: -20px;
    top: -30px;
}

.our-team .simple__banner .simple__left {
    margin: 0 auto;
    max-width: 70%;
}

.our-team .simple__right {
    margin: 0 auto;
}

.teamCard {
    z-index: 2;
    width: 40%;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    flex-direction: column;
}

}

@media screen and (max-width: 767px) {

    .our-team .title__banner {
        padding-bottom: 50px;
    }

    .teamCard {
        width: 100%;
    }

    .team__cards_container{
        padding: 60px 0;
    }

    .team__objective-container {
        padding: 20px;
        justify-content: space-between;
        flex-direction: column;
        padding-bottom: 60px;
    }

    .team__objective-left, .team__objective-right {
        width: 100%;
    }

    .team__objective-right {
        gap: 30px;
        margin-top: 50px;
    }

    .our-team .simple__banner .container {
        gap: 0;
    }

    .our-team .simple__banner .simple__left {
        max-width: 100%;
        margin-bottom: 40px;
    }
}