.hori__banner_wrapper{
    background-color: #fff;
}
.hori__banner {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 1180px;
    margin: 0px auto;
    position: relative;
    padding: 100px 0;
    background-color: #fff;
}

.hori__banner_right,
.hori__banner_left {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 50%;
}

.title__small{
    color: #BB2525;
}

.hori__banner_left {
    max-width: 500px;
    margin-right: 120px;
    position: sticky;
    top: 120px;
}

.hori__banner_left p{
    padding-left: 20px;
}

.hori__banner_right {
    gap: 50px;
    justify-content: unset;
    padding: 20px;
    position: relative;
}

/* .hori__banner_left:after {
    content: '';
    width: 315px;
    height: 320px;
    position: absolute;
    top: 20%;
    right: -100%;
    border-radius: 297.181px;
    background: #00c2fffc;
    filter: blur(200px);
} */

.hori__banner_left:after {
    content: '';
    width: 315px;
    height: 320px;
    position: absolute;
    top: 20%;
    border-radius: 297.181px;
    background: #00c2fffc;
    filter: blur(200px);
    z-index: -1;
    left: 50vw;
}

@-moz-document url-prefix() {
    .hori__banner_left:after {
        background: #9ad8ecc4; 
    }
}

.hori__banner_left .clouds {
    right: -50vw;
    position: absolute;
    z-index: -1;
}

.hori__banner_right::-webkit-scrollbar-thumb {
    scrollbar-color: #d4aa70 #e4e4e4;
    background-image: linear-gradient(90deg, var(--secondary) 0%, var(--primary) 99%);
    box-shadow: inset 2px 2px 5px 0 rgba(#fff, 0.5);
    border-radius: 100px;
}


.hori__banner_right::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #F5F5F5;
}

.hori__banner_right::-webkit-scrollbar {
    height: 8px;
    background-color: #F5F5F5;
    width: 3px;
}

.hori__banner_right::-webkit-scrollbar-thumb {
    background-color: #000000;
    border: 2px solid #555555;
}


.hori__banner h3 {
    color: #000;
    font-size: 36px;
    font-weight: 600;
}

h3.main {
    position: relative;
    padding: 20px;
    text-transform: capitalize;
}

h3.main::after {
    content: '';
    position: absolute;
    background-color: #BB2525;
    width: 40px;
    height: 3px;
    top: 0;
    left: 0;
}

h3.main::before {
    content: '';
    position: absolute;
    background-color: #BB2525;
    width: 3px;
    height: 40px;
    top: 0;
    left: 0;
}

.hori__banner .hori__banner_left ul {
    display: flex;
    justify-content: center;
    gap: 20px;
    flex-direction: column;
    border-left: 1px solid #AFAFAF;
    margin-top: 50px;
    margin-left: 20px;
}

.hori__banner .hori__banner_left ul li {
    color: #000;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    padding: 0 20px;
}

.hori__banner .hori__banner_left ul li.active__link {
    font-weight: 500;
    position: relative;
}

.hori__banner .hori__banner_left ul li.active__link::before {
    content: '';
    background-color: #BB2525;
    width: 3px;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
}

.hori__banner .hori__card {
    display: flex;
    gap: 30px;
    padding: 20px;
    border-radius: 44px;
    border: 4.5px solid #FFF;
    background: linear-gradient(106deg, #F7FCFF 58.25%, rgba(217, 240, 251, 0.00) 151.73%);
    box-shadow: 0px 45px 57px -32px rgba(0, 59, 102, 0.11);
    height: 180px;
    z-index: 2;
}

.hori__card .hori__content {
    display: flex;
    gap: 8px;
    flex-direction: column;
    padding: 0;
}

.hori__card .hori__card-img {
    max-width: 69px;
    min-width: 69px;
    height: 69px;
    width: 100%;
    border-radius: 50%;
    background: #DBF2FD;
    border: 2px solid #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
}

.hori__card .hori__card-img img{
    height: 40px;
    width: 40px;
}

.hori__card h3 {
    font-size: 20px;
    font-weight: 600;
    line-height: 22px;
}

.service__tabs {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1180px;
    margin: 100px auto;
}

.hori__banner_right .gradient__box{
    display: none;
}

@media screen and (max-width:1180px) {

    .hori__banner{
        padding: 50px 20px;
    }


    .hori__banner_right {
        width: 60%;
        padding-bottom: 30px;
    }

    .hori__banner_left {
        width: 40%;
    }

    .hori__banner .hori__card {
        gap: 10px;
        height: fit-content;
    }

    .hori__banner_left {
        margin-right: 50px;
    }

    .hori__banner_right .clouds {
        left: 0%;
    }

    .hori__card h3 {
        font-size: 16px;
    }

    .hori__banner_left:after {
        right: 0;
    }

    .hori__card .hori__content {
        gap: 10px;
    }


    .hori__banner .hori__banner_left ul li {
        color: #000;
        font-size: 12px;
    }

}


@media screen and (max-width:767px) {

    .hori__banner {
        margin: 20px 0;
    }

    .hori__banner .hori__banner_left ul {
        display: none;
    }

    .hori__banner_right .gradient__box{
        display: block;
    }


    .hori__banner_left .clouds {
        left: -100px;
        top: 220%;
    }


    .hori__banner_right,
    .hori__banner_left {
        width: 100%;
        padding: 20px 0;
        gap: 20px;
    }

    .hori__banner {
        flex-direction: column;
        gap: 0px;
        margin-bottom: 0px;
        padding-top: 0;
        padding-bottom: 0;
    }

    .hori__banner_left {
        gap: 10px;
        margin: 0;
        position: relative;
        padding-bottom: 0px;
        top: 0;
        margin-bottom: 30px;
    }

    .hori__banner_right{
        padding-top: 0;
    }

    .hori__card .hori__content {
        gap: 10px;
        padding: 0px;
    }

    .hori__banner .hori__card {
        padding: 20px;
        height: fit-content;
    }

    .hero__banner_wrapper {
        margin-top: 20px;
    }

    .hero__banner_wrapper {

        padding: 0;
    }

    .hori__banner_left:after {
        top: 400%;
        left: unset;
    }

    .hori__banner h3.main {
        font-size: 28px;
        padding-bottom: 0;
    }

}